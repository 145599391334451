import { ReactComponent as PinkSearchSVG } from "../../../assets/icons/pinkSearch.svg";
import styles from "./styles.module.scss";

interface IProps {
  search: string;
  setSearch: (str: string) => void;
}

const SearchBar = ({search, setSearch}: IProps) => {
  return (
    <div className={styles.inputWrapper}>
      <PinkSearchSVG />
      <input
        className={styles.searchInput}
        placeholder="SEARCH..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
    </div>
  );
};

export default SearchBar;