import React, { useState, useEffect, ChangeEvent } from "react";
import { Formik, Field, Form, ErrorMessage, FormikHelpers } from "formik";
import * as Yup from "yup";
import FormikReactSelect from "../FunctionalComponents/FormikReactSelect";

import { getUserBoard } from "../../services/user.service";
import SideBar from "../FunctionalComponents/Sidebar";
import { parseFile } from "../../utils/processFileData";
import { columns } from "../../utils/columns";
import { useNavigate } from "react-router-dom";

const ConvertData = () => {
    const navigate = useNavigate();
    const [content, setContent] = useState<string>("");
    const [successful, setSuccessful] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [fieldValue, setFieldValue] = useState<any>();
    const [newmessage, setNewmessage] = useState<string>("");
    const [useName, setUseName] = useState<boolean>(false);
    const [useTitle, setUseTitle] = useState<boolean>(false);
    const [useDate, setUseDate] = useState<boolean>(false);
    const [useEmail, setUseEmail] = useState<boolean>(false);
    const [useZip, setUseZip] = useState<boolean>(false);
    const [useCity, setUseCity] = useState<boolean>(false);
    const [useColumns, setUseColumns] = useState<any>([]);
    const [useImport, setUseImport] = useState<boolean>(true);

    const options = [
        { value: "name", label: "Convert name" },
        { value: "title", label: "Convert title" },
        { value: "date", label: "Convert date" },
        { value: "email", label: "Convert email" },
        { value: "zipcode", label: "Convert zip" },
        { value: "city", label: "Convert city" }
      ];

    useEffect(() => {
        getUserBoard().then(
            (response: any) => {
                setContent(response.data.username);
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                if (_content === "Unauthorized") {
                    localStorage.removeItem("user");
                    navigate("/login");
                    window.location.reload();
                }

                setContent(_content);
            }
        );
    }, []);

    const initialValues: {
        file: any,
        selectOptions: any;
      } = {
        file: [],
        selectOptions: [],
    };

    const validationSchema = Yup.object().shape({});

    const onChangeConvertData = async (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            parseFile(event.target.files[0], setFieldValue, columns, setUseColumns);
            setUseImport(false);
        }
    }

    const handleConvertData = async (formValue: { file: any }, { resetForm }: FormikHelpers<any>) => {
        let { file } = formValue;
        let newArr: any = [];
        if (fieldValue.length > 0) {
            file = fieldValue;
            file.map((item: any) => {
                const dateB = item.birthdate.split('.').join('-');
                const newName = useName ? item.firstname + ' ' + item.lastname : { firstname: item.firstname, lastname: item.lastname };
                let newTitle = '';
                if (item.title === 'Mr') {
                    newTitle = 'Herr';
                } else if (item.title === 'Mrs') {
                    newTitle = 'Frau';
                } else {
                    newTitle = 'Diverse';
                };
                item = {
                    email: item.emailaddress,
                    Geburtsdatum: useDate ? dateB : item.birthdate,
                    name: newName,
                    title: useTitle ? newTitle : item.title,
                    plz: item.zipcode,
                    ort: item.city
                }
                newArr.push(item);
            })
        }
        console.log('<<<<<<<<<<<<Converted data :>> ', newArr);
        setMessage("");
        setNewmessage('');
        setUseColumns(columns);
        resetForm();
    };

    return (
        <div className="for-height">
            <SideBar />
            <div className="col-md-12">
                <div className="card card-container2" style={{ marginTop: 0, marginLeft: 250 }}>
                    <h3 className="login-header">Convert data</h3>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleConvertData}
                    >
                        <Form>
                            <div className="form-group">
                                <label htmlFor="savedata"> Select file </label>
                                <Field
                                    name="file"
                                    type="file"
                                    className="form-control"
                                    onInput={onChangeConvertData}
                                />
                                <ErrorMessage
                                    name="file"
                                    component="div"
                                    className="alert alert-danger"
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="convertdata"> Select options </label>
                                <FormikReactSelect
                                    name="selectOptions"
                                    isMulti={true}
                                    options={options}
                                    setUseName={setUseName}
                                    setUseTitle={setUseTitle}
                                    setUseDate={setUseDate}
                                    setUseEmail={setUseEmail}
                                    setUseZip={setUseZip}
                                    setUseCity={setUseCity}
                                />
                                <ErrorMessage
                                    name="convertdata"
                                    component="div"
                                    className="alert alert-danger"
                                />
                            </div>

                            <div className="form-group">
                                <button type="submit" className="btn btn-submit btn-block">Convert data</button>
                            </div>
                            {message && (
                                <div className="form-group">
                                    <div className="alert alert-danger" role="alert">
                                        {message}
                                    </div>
                                </div>
                            )}
                        </Form>
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default ConvertData;