import { useQuery, useQueryClient } from "@tanstack/react-query";
import { uploadCenterService } from "../../../services/uploadCenter";
import { QUERY_KEYS } from "../../../common/queryKeys";
import styles from "./styles.module.scss";
import ListOfLists from "./components/ListOfLists";
import { Link } from "react-router-dom";
import { ReactComponent as PinkSearchSVG } from "../../../assets/icons/pinkSearch.svg";
import { useEffect } from "react";
import { useSortUploadLists } from "../../../customHooks/useSortUploadLists";

const UploadCenter = () => {
  const queryClient = useQueryClient();

  const { search, setSearch, sortQuery, order } = useSortUploadLists();

  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.getAllUploadedLists],
    queryFn: () => uploadCenterService.getAllLists(search, sortQuery, order),
  });

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      queryClient.cancelQueries({
        queryKey: [QUERY_KEYS.getAllUploadedLists],
      });

      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.getAllUploadedLists],
      });
    }, 200);

    return () => clearTimeout(timeoutId);
  }, [search, sortQuery, order]);

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.mainHeader}>Select list</h2>
      <div className={styles.searchAddBlock}>
        <div className={styles.inputWrapper}>
          <PinkSearchSVG />
          <input
            className={styles.searchInput}
            placeholder="SEARCH..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <Link to="/uploadlist">
          <button className={styles.addButton}>
            <span>+</span>Add new list
          </button>
        </Link>
      </div>
      <div className={styles.listWrapper}>
        <br />
        {data?.length ? (
          <ListOfLists lists={data} />
        ) : (
          <div>No lists. Create a new one...</div>
        )}
      </div>
    </div>
  );
};

export default UploadCenter;
