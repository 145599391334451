import Papa from "papaparse";
import UserData from '../types/userdata.type';
import CSVRowData from '../types/csvrowdata.type';

export async function parseCSV(csvData: string): Promise<UserData[]> {
    return new Promise((resolve, reject) => {
        Papa.parse(csvData, {
            header: true,
            complete: (results) => {
                resolve(results.data as UserData[]);
            },
            error: (error: any) => reject(error),
        });
    });
}

export async function parseNewCSV(csvData: string): Promise<CSVRowData> {
    return new Promise((resolve, reject) => {
        Papa.parse(csvData, {
            header: true,
            complete: (results) => {
                resolve(results.data as CSVRowData);
            },
            error: (error: any) => reject(error),
        });
    });
}
