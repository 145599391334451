import { useState, useEffect } from "react";
import { getUserBoard } from "../../services/user.service";
import { useNavigate } from "react-router-dom";

const TemplateCenter = () => {
    const navigate = useNavigate();
    const [content, setContent] = useState<string>("");
    
    useEffect(() => {
        getUserBoard().then(
            (response: any) => {
                setContent(response.data.username);
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                if (_content === "Unauthorized") {
                    localStorage.removeItem("user");
                    navigate("/login");
                    window.location.reload();
                }

                setContent(_content);
            }
        );
    }, []);

    return (
        <div className="for-height">
            <div className="col-md-12 col-12-new">
                <div className="card card-container2 new-card">
                    <h3 className="login-header">Template Center</h3>
                    <div>
                        Here, various templates are to be created, which can then be downloaded later from the individual lists as header/footer templates with the corresponding data and images.
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TemplateCenter;