import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const getContacts = async (): Promise<any> => {
    try {
        const { data } = await axios.get(`${API_URL}contacts`);
        return data;
    } catch (error) {
        console.error('Error fetching contacts:', error);
        throw error;
    }
};

export const getContactsByEmail = async (email: string | null): Promise<any> => {
    try {
        const { data } = await axios.get(`${API_URL}contacts/${email}`);
        return data;
    } catch (error: any) {
        if (error.response && error.response.data && error.response.data.message) {
            return error.response.data.message;
        } else {
            return 'Error fetching condact details:';
        }
    }
};
