// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__eI0OR {
  padding: 30px 40px;
}

.styles_listWrapper__C-8bx {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  gap: 50px;
}

.styles_list__TeyvH li {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}

.styles_addWrapper__mrbls {
  display: flex;
  gap: 10px;
}

.styles_mainHeader__lOezr {
  font-weight: 700;
  margin: 0;
}

.styles_deliverersWrapper__ugPuk {
  margin-top: 60px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  gap: 50px;
}`, "",{"version":3,"sources":["webpack://./src/components/OptComponents/AdminSettings/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,cAAA;EAAA,SAAA;AACF;;AAGE;EACE,aAAA;EACA,8BAAA;EACA,cAAA;AAAJ;;AAIA;EACE,aAAA;EACA,SAAA;AADF;;AAIA;EACE,gBAAA;EACA,SAAA;AADF;;AAIA;EACE,gBAAA;EACA,aAAA;EACA,8BAAA;EACA,cAAA;EAAA,SAAA;AADF","sourcesContent":[".wrapper {\n  padding: 30px 40px;\n}\n\n.listWrapper {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 50px;\n}\n\n.list {\n  li {\n    display: flex;\n    justify-content: space-between;\n    padding: 5px 0;\n  }\n}\n\n.addWrapper {\n  display: flex;\n  gap: 10px;\n}\n\n.mainHeader {\n  font-weight: 700;\n  margin: 0;\n}\n\n.deliverersWrapper {\n  margin-top: 60px;\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__eI0OR`,
	"listWrapper": `styles_listWrapper__C-8bx`,
	"list": `styles_list__TeyvH`,
	"addWrapper": `styles_addWrapper__mrbls`,
	"mainHeader": `styles_mainHeader__lOezr`,
	"deliverersWrapper": `styles_deliverersWrapper__ugPuk`
};
export default ___CSS_LOADER_EXPORT___;
