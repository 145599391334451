import React from "react";
import styles from "./styles.module.scss";

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isOutline?: boolean;
  isRed?: boolean;
}

const Button = ({
  isOutline = false,
  isRed = false,
  children,
  ...buttonProps
}: IProps) => {
  return (
    <button
      className={`${styles.button} ${isRed ? styles.red : ""} ${
        isOutline ? (isRed ? styles.redOutline : styles.commonOutline) : ""
      } ${buttonProps.disabled ? styles.disabled : ""} `}
      {...buttonProps}
    >
      {children}
    </button>
  );
};

export default Button;
