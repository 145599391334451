import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { adminSettingsService } from "../../../services/admin_settings";
import { QUERY_KEYS } from "../../../common/queryKeys";
import styles from "./styles.module.scss";
import { Field, Form, Formik } from "formik";
import Button from "../../Common/Button";

const AdminSettings = () => {
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.getAdminSettings],
    queryFn: adminSettingsService.getAdminSettings,
  });

  const { mutate: updateFunc } = useMutation({
    mutationFn: adminSettingsService.updateAdminSettings,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.getAdminSettings],
      });
    },
  });

  const addFemale = ({ femaleTitle }, { resetForm }) => {
    const list = [...data!.femaleTitles, femaleTitle];
    updateFunc({ femaleTitles: list });
    resetForm();
  };

  const addMale = ({ maleTitle }, { resetForm }) => {
    const list = [...data!.maleTitles, maleTitle];
    updateFunc({ maleTitles: list });
    resetForm();
  };

  const addDeliverer = ({ delivererList }, { resetForm }) => {
    const list = [...data!.delivererList, delivererList];
    updateFunc({ delivererList: list });
    resetForm();
  };

  const deleteFemale = (idx: number) => {
    const list = [...data!.femaleTitles];
    list.splice(idx, 1);
    updateFunc({ femaleTitles: list });
  };

  const deleteMale = (idx: number) => {
    const list = [...data!.maleTitles];
    list.splice(idx, 1);
    updateFunc({ maleTitles: list });
  };

  const deleteDeliverer = (idx: number) => {
    const list = [...data!.delivererList];
    list.splice(idx, 1);
    updateFunc({ delivererList: list });
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.mainHeader}>Gender detection settings:</h2>
      <br />
      <div className={styles.listWrapper}>
        <ul className={styles.list}>
          <li>Male titles:</li>
          {data?.maleTitles.map((i, idx) => (
            <li>
              <span>{i}</span>
              <div>
                <Button onClick={() => deleteMale(idx)} isOutline>
                  delete
                </Button>
              </div>
            </li>
          ))}
        </ul>

        <ul className={styles.list}>
          <li>Female titles:</li>
          {data?.femaleTitles.map((i, idx) => (
            <li>
              <span>{i}</span>
              <div>
                <Button onClick={() => deleteFemale(idx)} isOutline>
                  delete
                </Button>
              </div>
            </li>
          ))}
        </ul>
      </div>

      <div className={styles.listWrapper}>
        <Formik initialValues={{ maleTitle: "" }} onSubmit={addMale}>
          <Form>
            <div className={styles.addWrapper}>
              <Field
                id="maleTitle"
                name="maleTitle"
                type="text"
                placeholder="Add title..."
              />
              <div>
                <Button type="submit">Add</Button>
              </div>
            </div>
          </Form>
        </Formik>

        <Formik initialValues={{ femaleTitle: "" }} onSubmit={addFemale}>
          <Form>
            <div className={styles.addWrapper}>
              <Field
                id="femaleTitle"
                name="femaleTitle"
                type="text"
                placeholder="Add title..."
              />
              <div>
                <Button type="submit">Add</Button>
              </div>
            </div>
          </Form>
        </Formik>
      </div>

      <div className={styles.deliverersWrapper}>
        <div>
          <ul className={styles.list}>
            <li>Deliverers:</li>
            {data?.delivererList.map((i, idx) => (
              <li>
                <span>{i}</span>
                <div>
                  <Button onClick={() => deleteDeliverer(idx)} isOutline>
                    delete
                  </Button>
                </div>
              </li>
            ))}
          </ul>

          <Formik initialValues={{ delivererList: "" }} onSubmit={addDeliverer}>
            <Form>
              <div className={styles.addWrapper}>
                <Field
                  id="delivererList"
                  name="delivererList"
                  type="text"
                  placeholder="Add deliverer..."
                />
                <div>
                  <Button type="submit">Add</Button>
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default AdminSettings;
