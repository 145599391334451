export const columns = [
    {
        Header: "Users data",
        columns: [
            { Header: "Email", accessor: "emailaddress" },
            { Header: "First Name", accessor: "firstname" },
            { Header: "Last Name", accessor: "lastname" },
            { Header: "Birth date", accessor: "birthdate" },
            { Header: "Title", accessor: "title" },
            { Header: "Zip code", accessor: "zipcode" },
            { Header: "City", accessor: "city" },
            { Header: "Street Address", accessor: "streetaddress" }
        ]
    }
];

export const columnsMap = [
    {
        Header: "Users mapped data",
        columns: [
            { Header: "Email", accessor: "emailaddress" },
            { Header: "First Name", accessor: "firstname" },
            { Header: "Last Name", accessor: "lastname" },
            { Header: "Birth date", accessor: "birthdate" },
            { Header: "Title", accessor: "title" },
            { Header: "Zip code", accessor: "zipcode" },
            { Header: "City", accessor: "city" },
            { Header: "Street Address", accessor: "streetaddress" }
        ]
    }
];

export const columnsModal = [
    {
        Header: "Users modal data",
        columns: [
            { Header: "Email", accessor: "emailaddress" },
            { Header: "First Name", accessor: "firstname" },
            { Header: "Last Name", accessor: "lastname" },
            { Header: "Birth date", accessor: "birthdate" },
            { Header: "Title", accessor: "title" },
            { Header: "Contry code", accessor: "countryCode" },
            { Header: "Zip code", accessor: "zipcode" },
            { Header: "Zip API", accessor: "zip" },
            { Header: "City API", accessor: "cityAPI" },
            { Header: "City", accessor: "city" },
            { Header: "Latitude", accessor: "lat" },
            { Header: "Longitude", accessor: "lon" },
            { Header: "Street Address", accessor: "streetaddress" },
            { Header: "Purchasing Power Person Year", accessor: "pur_power_person" },
            { Header: "Purchasing Power House Year", accessor: "pur_power_house" },
            { Header: "Purchasing Power Person Month", accessor: "pur_power_person_month" },
            { Header: "Purchasing Power House Month", accessor: "pur_power_house_month" },
            { Header: "Purchasing Index Person", accessor: "pur_index_person" },
            { Header: "Purchasing Index House", accessor: "pur_index_house" },
            { Header: "Population Household Division", accessor: "population_household" }
        ]
    }
];

export const columnsConverted = [
    {
        Header: "Users converted data",
        columns: [
            { Header: "Email", accessor: "email" },
            { Header: "Full Name", accessor: "name" },
            { Header: "Geburtsdatum", accessor: "Geburtsdatum" },
            { Header: "Title", accessor: "title" },
            { Header: "Plz", accessor: "plz" },
            { Header: "Ort", accessor: "ort" }
        ]
    }
];
