// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_inputWrapper__NgUkH {
  border: 1px solid #e4348b;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 7px 8px;
  width: 460px;
}

.styles_searchInput__n3-NK {
  border: none;
  font-size: 14px;
  color: #f0bdd6;
  outline: none;
  width: 100%;
}
.styles_searchInput__n3-NK::placeholder {
  color: #f0bdd6;
}`, "",{"version":3,"sources":["webpack://./src/components/FunctionalComponents/SearchBar/styles.module.scss","webpack://./src/styles/variables.module.scss"],"names":[],"mappings":"AAEA;EACE,yBAAA;EACA,kBAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,YAAA;AADF;;AAIA;EACE,YAAA;EACA,eAAA;EACA,cCTiB;EDUjB,aAAA;EACA,WAAA;AADF;AAGE;EACE,cCde;ADanB","sourcesContent":["@import \"../../../styles/variables.module.scss\";\n\n.inputWrapper {\n  border: 1px solid $pink-color;\n  border-radius: 8px;\n  overflow: hidden;\n  display: flex;\n  align-items: center;\n  padding: 7px 8px;\n  width: 460px;\n}\n\n.searchInput {\n  border: none;\n  font-size: 14px;\n  color: $light-pink-color;\n  outline: none;\n  width: 100%;\n\n  &::placeholder {\n    color: $light-pink-color;\n  }\n}","// COLORS\n\n$white-color: #fff;\n$black-color: #000;\n$red-color: #d96059;\n$pink-color: #e4348b;\n$light-pink-color: #f0bdd6;\n$quite-light-pink-color: #feecf5;\n$super-light-pink-color: #fffbfd;\n$orange-color: #f28c00;\n$dark-color: #1c1c1e;\n\n$grey: #2b3343;\n$grey80: #575f6e;\n$grey60: #9a9ea5;\n$grey40: #aaadb5;\n$grey30: #d4d4d4;\n$grey25: #e6e6e6;\n$grey20: #f7f7f8;\n\n$success-color: #7dad3a;\n$success-color-light: #a7c779;\n$success-color-dark: #648a2e;\n$success-pale-color: #e5efd8;\n$success-pale-2-color: #eaf3dd;\n$warning-color: #c72b23;\n$warning-color-dark: #9f221c;\n$warning-color-light: #d86b65;\n$warning-pale-color: #f4d5d3;\n$warning-color10: #fee6e5;\n$caution-color: #f8b027;\n\n// FONT-SIZES\n\n$h1: 56px;\n$h2: 40px;\n$h3: 26px;\n$h4: 24px;\n$h5: 12px;\n$p: 8px;\n\n// MIXINS\n\n@mixin fullSize {\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputWrapper": `styles_inputWrapper__NgUkH`,
	"searchInput": `styles_searchInput__n3-NK`
};
export default ___CSS_LOADER_EXPORT___;
