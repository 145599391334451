import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";

import { ReactComponent as ListManagerSVG } from "../../../assets/icons/menu/listmanager.svg";
import { ReactComponent as LeadGenSVG } from "../../../assets/icons/menu/leadGenPartner.svg";
import { ReactComponent as UploadCenterSVG } from "../../../assets/icons/menu/uploadCenter(1).svg";
import { ReactComponent as OptInCenterSVG } from "../../../assets/icons/menu/optInSearch.svg";
import { ReactComponent as ExportCenterSVG } from "../../../assets/icons/menu/exportCenter.svg";
import { ReactComponent as UnsubscribeSVG } from "../../../assets/icons/menu/unsubscribeContact.svg";
import { ReactComponent as UserManagerSVG } from "../../../assets/icons/menu/userManager.svg";
import { ReactComponent as TemplateCenterSVG } from "../../../assets/icons/menu/templateCenter.svg";
import { ReactComponent as DashboardsSVG } from "../../../assets/icons/menu/dashboards.svg";
import { ReactComponent as AdminSettingsSVG } from "../../../assets/icons/menu/settings.svg";
import { ReactComponent as CloseSidebarSVG } from "../../../assets/icons/menu/closeSidebar.svg";
import { ReactComponent as OpenSidebarSVG } from "../../../assets/icons/menu/openSidebar.svg";
import { ReactComponent as SmallLogoSVG } from "../../../assets/icons/SmallLogo.svg";

const Sidebar: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [openNext, setOpenNext] = useState<boolean>(true);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`${styles.sidebar} ${isOpen ? styles.sidebarOpen : ""}`}>
      <div className={styles.sidebarContent}>
        <div
          className={`${styles.logoContainer} ${
            isOpen ? styles.biggerLogo : ""
          }`}
        >
          <Link to="https://reachad.de/" target="_blank">
            <SmallLogoSVG />
          </Link>
        </div>
        <ul className={styles.content}>
          <li
            className={styles.listElement}
            onClick={() => {
              setOpenNext(!openNext);
            }}
          >
            <Link to={"/listmanager"} className={styles.listElement}>
              <ListManagerSVG className={styles.stroke} />{" "}
              <span>LISTMANAGER</span>
            </Link>
          </li>

          <li className={styles.listElement}>
            <Link to={"/leadgenpartner"} className={styles.listElement}>
              <LeadGenSVG className={styles.stroke} />{" "}
              <span>LEAD GEN PARTNER</span>
            </Link>
          </li>

          <li className={styles.listElement}>
            <Link to={"/uploadcenter"} className={styles.listElement}>
              <UploadCenterSVG className={styles.fill} />{" "}
              <span>UPLOAD CENTER</span>
            </Link>
          </li>

          <li className={styles.listElement} onClick={() => setOpen(!open)}>
            <Link to={"/optinsearch"} className={styles.listElement}>
              <OptInCenterSVG className={styles.fill} />{" "}
              <span>OPT-IN SEARCH</span>
            </Link>
          </li>

          <li className={styles.listElement}>
            <Link to={"/selectionexport"} className={styles.listElement}>
              <ExportCenterSVG className={styles.stroke} />{" "}
              <span>SELECTION AND EXPORT CENTER/MANAGER</span>
            </Link>
          </li>

          <li className={styles.listElement}>
            <Link to={"/unsubscribecontact"} className={styles.listElement}>
              <UnsubscribeSVG className={styles.fill} />{" "}
              <span>UNSUBSCRIBE CONTACT</span>
            </Link>
          </li>

          <li className={styles.listElement}>
            <Link to={"/usermanager"} className={styles.listElement}>
              <UserManagerSVG className={styles.fill} />{" "}
              <span>USER MANAGER AND LOG-CENTER</span>
            </Link>
          </li>

          <li className={styles.listElement}>
            <Link to={"/templatecenter"} className={styles.listElement}>
              <TemplateCenterSVG className={styles.stroke} />{" "}
              <span>TEMPLATE CENTER</span>
            </Link>
          </li>

          <li className={styles.listElement}>
            <Link to={"/dashboards"} className={styles.listElement}>
              <DashboardsSVG className={styles.fill} /> <span>DASHBOARDS</span>
            </Link>
          </li>

          <li className={styles.listElement}>
            <Link to={"/adminsettings"} className={styles.listElement}>
              <AdminSettingsSVG className={styles.stroke} />{" "}
              <span>ADMIN SETTINGS</span>
            </Link>
          </li>
        </ul>

        <button className={styles.collapseButton} onClick={toggleSidebar}>
          {isOpen ? <CloseSidebarSVG /> : <OpenSidebarSVG />}
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
