import React, { useState } from "react";
import {
  unsubscribeContact,
  batchUnsubscribeContacts,
} from "../../services/unsubscribe.service";

const UnsubscribeForm: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [reason, setReason] = useState<string>("too_many_messages");
  const [message, setMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [globalMessage, setGlobalMessage] = useState<string | null>(null);
  const [globalError, setGlobalError] = useState<string | null>(null);
  const [batchMessage, setBatchMessage] = useState<string | null>(null);
  const [batchError, setBatchError] = useState<string | null>(null);
  const [listID, setListID] = useState<number>(0);
  const [emails, setEmails] = useState<string[]>([]);
  const [globalUnsubscribe, setGlobalUnsubscribe] = useState<boolean>(false);

  const handleUnsubscribe = async () => {
    setGlobalError("");
    setGlobalMessage("");
    try {
      const response = await unsubscribeContact(email, reason, listID);
      if (typeof response === "string") {
        setError(response);
      } else if (response && response.result === "Success") {
        setMessage(response.result);
        setError("");
      } else {
        setError("Failed to process unsubscribe");
      }
    } catch (error) {
      setMessage("Failed to unsubscribe");
    }
  };

  const handleBatchUnsubscribe = async () => {
    try {
      const responses = await batchUnsubscribeContacts(emails, listID);
      setBatchMessage("Batch unsubscribe completed");
      setBatchError("");
    } catch (error) {
      setBatchError("Failed to batch unsubscribe");
    }
  };

  const handleGlobalUnsubscribe = async () => {
    setError("");
    setMessage("");
    try {
      const response = await unsubscribeContact(email, reason, -1);
      if (typeof response === "string") {
        setGlobalError(response);
      } else if (response && response.result === "Success") {
        setGlobalMessage(response.result);
        setGlobalError("");
      } else {
        setGlobalError("Failed to process global unsubscribe");
      }
    } catch (error) {
      setGlobalError("Failed to global unsubscribe");
    }
  };

  return (
    <div>
      <h3>Unsubscribe Contact</h3>
      <div>
        <label>Email:</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div>
        <label>Reason:</label>
        <select value={reason} onChange={(e) => setReason(e.target.value)}>
          <option value="too_many_messages">Too Many Messages</option>
          <option value="no_longer_interested">No Longer Interested</option>
        </select>
      </div>
      <div>
        <label>List ID:</label>
        <input
          type="number"
          value={listID}
          onChange={(e) => setListID(Number(e.target.value))}
        />
      </div>
      <div>
        <label>Global Unsubscribe:</label>
        <input
          type="checkbox"
          checked={globalUnsubscribe}
          onChange={(e) => setGlobalUnsubscribe(e.target.checked)}
        />
      </div>
      <button
        onClick={
          globalUnsubscribe ? handleGlobalUnsubscribe : handleUnsubscribe
        }
      >
        {globalUnsubscribe ? "Global Unsubscribe" : "Unsubscribe"}
      </button>
      {message && <p>{message}</p>}
      {error && <p>{error}</p>}
      {globalMessage && <p>{globalMessage}</p>}
      {globalError && <p>{globalError}</p>}

      <h3>Batch Unsubscribe Contacts</h3>
      <div>
        <label>Emails (comma-separated):</label>
        <input
          type="text"
          onChange={(e) =>
            setEmails(e.target.value.split(",").map((email) => email.trim()))
          }
        />
      </div>
      <button onClick={handleBatchUnsubscribe}>Batch Unsubscribe</button>
      {batchMessage && <p>{batchMessage}</p>}
      {batchError && <p>{batchError}</p>}
    </div>
  );
};

export default UnsubscribeForm;
