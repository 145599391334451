import { Form, Formik } from "formik";

import styles from "./styles.module.scss";

const UnsubscribeContact = () => {
  return (
    <div className={styles.wrapper}>
      <h3 className={styles.header}>Unsubscribe/Contact Center</h3>
      <div className={styles.noteText}>
        In this function you can unsubscribe users individually or in bulk via
        upload or multiple form, blacklist. Per API these users are blacklisted
        or unsubscribed in the mailingtools.
      </div>

      <br />

      <div className={styles.content}>
        <Formik initialValues={{ email: "" }} onSubmit={() => {}}>
          <Form>
            <h3>Unsubscribe Contact</h3>

            <div>
              <label>List ID:</label>
              <input />
            </div>
            <div>
              <label>Global Unsubscribe:</label>
              <input type="checkbox" />
            </div>

            <div>
              <label>Email:</label>
              <input />
            </div>
            <div>
              <label>Reason:</label>
              <select>
                <option value="too_many_messages">Too Many Messages</option>
                <option value="no_longer_interested">
                  No Longer Interested
                </option>
              </select>
            </div>

            <button>Unsubscribe</button>
          </Form>
        </Formik>

        <br />
        <br />
        <br />

        <h3>Batch Unsubscribe Contacts</h3>
        <div>
          <label>Emails (comma-separated):</label>
          <input />
        </div>
        <button>Batch Unsubscribe</button>
      </div>
    </div>
  );
};

export default UnsubscribeContact;
