import IMap from "../types/map.type";
import IMaped from "../types/getmap.type";

export const mapApiResponseToType = (data: IMaped): IMap => {
    let divide: number = 0;
    let household: number = 0;
    let person: number = 0;
    if ((data && data.population) && (data && data.household)) {
        divide = parseFloat((data.population / data.household).toFixed(3));
    }
    if (data && data.pur_power_person) {
        person = parseFloat((data.pur_power_person/12).toFixed(3));
    }
    if (data && data.pur_power_house) {
        household = parseFloat((data.pur_power_house/12).toFixed(3));
    }
    return {
        household: data.household,
        population: data.population,
        pur_index_house: data.pur_index_house,
        pur_index_person: data.pur_index_person,
        pur_power_house: data.pur_power_house,
        pur_power_person: data.pur_power_person,
        population_household: divide,
        pur_power_person_month: person,
        pur_power_house_month: household,
        zipcode: data.zipcode
    };
}

export const defineDecPlaces = (data: number | null | undefined): number => {
    let result = 0;
    if (data) {
        result = data;
        result = Number(result.toFixed(2));
    } 
    return result;
}
