import { useField, useFormikContext } from "formik";
import React from "react";
import Select, { MultiValue, SingleValue } from "react-select";
import { StateManagerProps } from "react-select/dist/declarations/src/useStateManager";

type MyOption = {
  label: string;
  value: string;
};

type GroupedOption = {
  label: string;
  options: MyOption[];
};

type Props = {
  name: string;
  setUseName?: (useName: boolean) => void;
  setUseTitle?: (useTitle: boolean) => void;
  setUseDate?: (useDate: boolean) => void;
  setUseEmail?: (useEmail: boolean) => void;
  setUseZip?: (useZip: boolean) => void;
  setUseCity?: (useCity: boolean) => void;
} & Omit<
  StateManagerProps<MyOption, false | true, GroupedOption>,
  "value" | "onChange"
>;

const FormikReactSelect: React.FC<Props> = ({
  name,
  setUseName,
  setUseTitle,
  setUseDate,
  setUseEmail,
  setUseZip,
  setUseCity,
  ...restProps
}) => {
  const [field] = useField(name);
  const { setFieldValue } = useFormikContext();

  const flattenedOptions = (restProps.options || []).flatMap((option) =>
    "value" in option ? option : option.options
  );

  const selectedValue = flattenedOptions.filter((option) =>
    Array.isArray(field.value)
      ? field.value.includes(option.value)
      : field.value === option.value
  );

  const handleChange = (
    selected: MultiValue<MyOption> | SingleValue<MyOption>
  ) => {
    if (!selected) {
      setFieldValue(name, []);
      return;
    }

    const selectedOptions = Array.isArray(selected) ? selected : [selected];
    const selectedValues = selectedOptions.map((opt) => opt.value);

    selectedValues.forEach((value) => {
      switch (value) {
        case "name":
          setUseName?.(true);
          break;
        case "title":
          setUseTitle?.(true);
          break;
        case "email":
          setUseEmail?.(true);
          break;
        case "zipcode":
          setUseZip?.(true);
          break;
        case "city":
          setUseCity?.(true);
          break;
        case "date":
          setUseDate?.(true);
          break;
        default:
          break;
      }
    });

    setFieldValue(name, selectedValues);
  };

  return (
    <Select
      {...restProps}
      value={selectedValue}
      onChange={handleChange}
      isMulti={restProps.isMulti}
    />
  );
};

export default FormikReactSelect;
