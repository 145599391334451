import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

export const saveData = (email: string) => {
    return axios.post(API_URL + "addemail", { email });
};

export const saveMultData = (data: any) => {
    return axios.post(API_URL + "multemail", data);
};