import React from 'react';
import { Table } from './Table';

interface TableContentProps {
    columns: any[];
    data: any[];
    customHeaders?: any[];
}

const TableContent: React.FC<TableContentProps> = ({ columns, data, customHeaders }) => (
    <div className="form-group table-init">
        <Table columns={columns} data={data} customHeaders={customHeaders} />
    </div>
);

export default TableContent;
