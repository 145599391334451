import UserData from '../../types/userdata.type';

export const generateColumnsWithActions = (
    useMapColumns: any,
    editRowIndex: number | null,
    saveEditRow: () => void,
    startEditingRow: (index: number) => void
) => {
    return [
        ...useMapColumns[0].columns,
        {
            Header: "Actions",
            accessor: "actions",
            Cell: ({ row }: any) => {
                const rowIndex = row.index;
                return (
                    editRowIndex === rowIndex ? (
                        <button className="border-fix" onClick={saveEditRow}>Save</button>
                    ) : (
                        <button className="border-fix" onClick={() => startEditingRow(rowIndex)}>Edit</button>
                    )
                );
            }
        }
    ];
};

export const generateEditableData = (
    mappedFieldValue: UserData[],
    useMapColumns: any,
    editRowIndex: number | null,
    editRowData: UserData | null,
    handleEditChange: (field: keyof UserData, value: string) => void
) => {
    return mappedFieldValue.map((row, rowIndex) => {
        const editableRow = { ...row };
        useMapColumns[0].columns.forEach((col: any) => {
            if (editRowIndex === rowIndex) {
                editableRow[col.accessor] = (
                    <input
                        type="text"
                        value={(editRowData as any)[col.accessor] || ''}
                        onChange={(e) => handleEditChange(col.accessor, e.target.value)}
                    />
                );
            } else {
                editableRow[col.accessor] = row[col.accessor];
            }
        });
        return editableRow;
    });
};

export const generateCustomHeaders = (
    useMapColumns: any,
    editHeaderIndex: number | null,
    editHeaderData: string,
    handleHeaderChange: (value: string) => void,
    saveEditHeader: () => void,
    startEditingHeader: (index: number, value: string) => void
) => {
    return [
        <tr key="header-group">
            <th colSpan={useMapColumns[0].columns.length + 1}>Users mapped data</th>
        </tr>,
        <tr key="headers">
            {useMapColumns[0].columns.map((col: any, colIndex: number) => {
                if (editHeaderIndex === colIndex) {
                    return (
                        <th key={col.accessor}>
                            <input
                                type="text"
                                value={editHeaderData}
                                onChange={(e) => handleHeaderChange(e.target.value)}
                            />
                            <button className="border-fix" onClick={saveEditHeader}>Save</button>
                        </th>
                    );
                }
                return (
                    <th key={col.accessor} onClick={() => startEditingHeader(colIndex, col.Header)}>
                        {col.Header}
                    </th>
                );
            })}
            <th>Actions</th>
        </tr>
    ];
};