import { createContext, useState, ReactNode } from "react";
import { convertTitleIntoSortQuery } from "../utils/Strings";

interface SearchSortContextType {
  search: string;
  sortQuery: string;
  order: string;
  setSearch: (value: string) => void;
  toggleSort: (value: string) => void;
}

export const SearchSortContext = createContext<
  SearchSortContextType | undefined
>(undefined);

export const SortUploadListProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [search, setSearch] = useState("");
  const [sortQuery, setSortQuery] = useState("updatedAt");
  const [order, setOrder] = useState("DESC");

  const toggleOrder = () =>
    setOrder((prev) => (prev === "DESC" ? "ASC" : "DESC"));

  const toggleSort = (value: string) => {
    const string = convertTitleIntoSortQuery(value);

    if (string === sortQuery) {
      toggleOrder();
      return;
    }

    setSortQuery(string);
    setOrder("DESC");
  };

  return (
    <SearchSortContext.Provider
      value={{ search, sortQuery, order, toggleSort, setSearch }}
    >
      {children}
    </SearchSortContext.Provider>
  );
};
