import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { getMailingName } from '../../services/mailings.service';

interface FormValues {
    idNumber: number | null;
}

const validationSchema = Yup.object({
    idNumber: Yup.string().required('ID Number is required'),
});

const MailingName: React.FC = () => {
    const [mailingName, setMailingName] = useState<string>('');
    const [error, setError] = useState<string>('');

    const fetchMailingName = async (idNumber: number | null) => {
        try {
            const name = await getMailingName(idNumber);
            setMailingName(name);
            setError('');
        } catch (err) {
            setError('Mailing not found');
            setMailingName('');
        }
    };

    const handleSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
        await fetchMailingName(values.idNumber);
        setSubmitting(false);
    };

    return (
        <div>
            <h3 className="login-header">Fetch Mailing Name</h3>
            <Formik
                initialValues={{ idNumber: null}}
                validationSchema={validationSchema}
                //@ts-ignore
                onSubmit={handleSubmit}
            >
                {() => (
                    <Form>
                        <div className="form-group">
                            <label htmlFor="idNumber">ID Number:</label>
                            <Field 
                                id="idNumber"
                                name="idNumber"
                                type="text"
                                className="form-control"
                            />
                            <ErrorMessage 
                                name="idNumber" 
                                component="div" 
                                className="text-danger" 
                            />
                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn btn-submit btn-block">
                                Get Mailing Name
                            </button>
                        </div>
                        <div className="form-group">
                            <label htmlFor="mailingName">Mailing Name:</label>
                            <input
                                id="mailingName"
                                name="mailingName"
                                type="text"
                                className="form-control"
                                value={mailingName}
                                disabled
                            />
                        </div>
                        {error && (
                            <div>
                                <p style={{ color: 'red' }}>{error}</p>
                            </div>
                        )}
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default MailingName;
