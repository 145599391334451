export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function lowercaseFirstLetter(string: string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

export const convertTitleIntoSortQuery = (value: string) =>
  value
    .split(" ")
    .map((i, idx) =>
      idx === 0 ? lowercaseFirstLetter(i) : capitalizeFirstLetter(i)
    )
    .join("");