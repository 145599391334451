export const listOfDBFields = [
  "skip",
  "email",
  "gender",
  "firstName",
  "lastName",
  "birthDate",
  "streetAddress",
  "houseNumber",
  "zipCode",
  "city",
  "country",
  "countryPhoneCode",
  "phoneAreaCode",
  "phoneNumber",
  "SOI_Date",
  "SOI_Ip",
  "SOI_UserAgent",
  "DOI_Date",
  "DOI_Ip",
  "DOI_UserAgent",
  "TOI_Date",
  "TOI_Ip",
  "TOI_UserAgent",
  "isBlocked",
  "blockedSince",
  "blockedByUser",
  "blockedReason",
  "notSpecifiedField1",
  "notSpecifiedField2",
  "notSpecifiedField3",
  "notSpecifiedField4",
  "notSpecifiedField5",
  "notSpecifiedField6",
  "notSpecifiedField7",
  "notSpecifiedField8",
  "notSpecifiedField9",
  "notSpecifiedField10",
];

export const autoAssignHelper = {
  email: ["mail", "email-adresse"],
  gender: ["gender", "geschlecht", "anrede"],
  firstName: ["first", "vorname"],
  lastName: ["last", "nachname"],
  birthDate: ["birthdate", "geburtsdatum"],
  streetAddress: ["street", "address", "straße"],
  houseNumber: ["housenumber", "hausnr", "hausnummer"],
  zipCode: ["zip", "plz"],
  city: ["city", "stadt"],
  country: ["country", "land"],
  countryPhoneCode: ["phonecode"],
  phoneAreaCode: ["areacode"],
  phoneNumber: ["phonenumber"],
  SOI_Date: ["soi_date", "soi_zeipunkt"],
  SOI_Ip: ["soi_ip", "soi-ip"],
  SOI_UserAgent: [],
  DOI_Date: ["doi_date", "doi_zeipunkt"],
  DOI_Ip: ["doi_ip", "doi-ip"],
  DOI_UserAgent: [],
  TOI_Date: ["toi_date", "toi_zeipunkt"],
  TOI_Ip: ["toi_ip", "toi-ip"],
  TOI_UserAgent: [],
  isBlocked: ["isblocked"],
  blockedSince: [],
  blockedByUser: [],
  blockedReason: [],
};