import IMap from "../types/map.type";
export const getPurchasingPowerData = async (purchasingData: IMap[], citiesData:any) => {
    const mydata:IMap[] = JSON.parse(JSON.stringify(purchasingData));
    const averagePurPowerPerson = (arr: IMap[]) => {
        let result = 0;
        if (arr.length > 0) {
            result = arr.reduce((p, c) => p + Number(c.pur_power_person), 0) /
                arr.length;
        }
        return result;
    }
    const averagePurPowerHouse = (arr: IMap[]) => {
        let result = 0;
        if (arr.length > 0) {
            result = arr.reduce((p, c) => p + Number(c.pur_power_house), 0) /
                arr.length;
        }
        return result;
    }
    const averageIndexPowerPerson = (arr: IMap[]) => {
        let result = 0;
        if (arr.length > 0) {
            result = arr.reduce((p, c) => p + Number(c.pur_index_person), 0) /
                arr.length;
        }
        return result;
    }
    const averageIndexPowerHouse = (arr: IMap[]) => {
        let result = 0;
        if (arr.length > 0) {
            result = arr.reduce((p, c) => p + Number(c.pur_index_house), 0) /
                arr.length;
        }
        return result;
    }

    const averagePopulationHousehold = (arr: IMap[]) => {
        let result = 0;
        if (arr.length > 0) {
          result = arr.reduce((p, c) => p + Number(c.population_household), 0) /
            arr.length;
        }
        return result;
    }

    const citiesStat:any = {
        male: {
          "Rural Municipality": 0,
          "Small Town": 0,
          "Small Medium-sized town": 0,
          "Large Medium-sized town": 0,
          "Small Metropolis": 0,
          "Large city": 0,
        },
        female: {
          "Rural Municipality": 0,
          "Small Town": 0,
          "Small Medium-sized town": 0,
          "Large Medium-sized town": 0,
          "Small Metropolis": 0,
          "Large city": 0,
        },
        other: {
          "Rural Municipality": 0,
          "Small Town": 0,
          "Small Medium-sized town": 0,
          "Large Medium-sized town": 0,
          "Small Metropolis": 0,
          "Large city": 0,
        }
      };
  
      let gender;
      for (let i = 0; i < mydata.length; ++i) {
        const foundCity = citiesData.data.find(
          (e:any) => e.postal_code == mydata[i].zipcode
        );
  
        if (foundCity) {
          const population = Number(foundCity.PopulationCount);
          if (population) {
            if (mydata[i].Gender === "Frau") {
              gender = "female";
            } else if (mydata[i].Gender === "Herr") {
              gender = "male";
            } else {
              gender = "other";
            }
            if (population <= 5000) {
              ++citiesStat[gender]["Rural Municipality"];
            } else if (population >= 5001 && population <= 9999) {
              ++citiesStat[gender]["Small Town"];
            } else if (population >= 20000 && population <= 49999) {
              ++citiesStat[gender]["Small Medium-sized town"];
            } else if (population >= 50000 && population <= 99999) {
              ++citiesStat[gender]["Large Medium-sized town"];
            } else if (population >= 100000 && population <= 499999) {
              ++citiesStat[gender]["Small Metropolis"];
            } else if (population >= 500000) {
              ++citiesStat[gender]["Large city"];
            } else {
            }
          }
        }
      }
      let citiesStatAll:any = {
        "Rural Municipality":
          citiesStat["male"]["Rural Municipality"] +
          citiesStat["female"]["Rural Municipality"] +
          citiesStat["other"]["Rural Municipality"],
        "Small Town":
          citiesStat["male"]["Small Town"] +
          citiesStat["female"]["Small Town"] +
          citiesStat["other"]["Small Town"],
        "Small Medium-sized town":
          citiesStat["male"]["Small Medium-sized town"] +
          citiesStat["female"]["Small Medium-sized town"] +
          citiesStat["other"]["Small Medium-sized town"],
        "Large Medium-sized town":
          citiesStat["male"]["Large Medium-sized town"] +
          citiesStat["female"]["Large Medium-sized town"] +
          citiesStat["other"]["Large Medium-sized town"],
        "Small Metropolis":
          citiesStat["male"]["Small Metropolis"] +
          citiesStat["female"]["Small Metropolis"] +
          citiesStat["other"]["Small Metropolis"],
        "Large city":
          citiesStat["male"]["Large city"] +
          citiesStat["female"]["Large city"] +
          citiesStat["other"]["Large city"],
      };

    const hfData = mydata.filter(
        (e:any) =>
            (typeof Number(e.pur_power_person) === "number" &&
                !isNaN(e.pur_power_person || 0)) &&
            (typeof Number(e.pur_power_house) === "number" &&
                !isNaN(e.pur_power_house || 0)) &&
            (typeof Number(e.pur_index_person) === "number" &&
                !isNaN(e.pur_index_person || 0)) &&
            (typeof Number(e.pur_index_house || 0) === "number" &&
                !isNaN(e.pur_index_house || 0)) &&
            (typeof Number(e.population_household) === "number" &&
                !isNaN(e.population_household || 0))
    )
    const herrs = hfData.filter((e) => e.Gender == "Herr")
    const fraus = hfData.filter((e) => e.Gender == "Frau")
    const others = hfData.filter((e) => e.Gender != "Frau" && e.Gender != "Herr")
    const result = {
        averagePurPowerPerson: averagePurPowerPerson(
            hfData
        ),
        averagePurPowerHouse: averagePurPowerHouse(
            hfData
        ),
        averageIndexPowerPerson: averageIndexPowerPerson(
            hfData
        ),
        averageIndexPowerHouse: averageIndexPowerHouse(
            hfData
        ),
        averagePopulationHousehold: averagePopulationHousehold(
          hfData
        ),
        averagePurPowerPersonMale: averagePurPowerPerson(
            herrs
        ),
        averagePurPowerPersonFemale: averagePurPowerPerson(
            fraus
        ),
        averagePurPowerPersonOther: averagePurPowerPerson(
            others
        ),
        averagePurPowerHouseMale: averagePurPowerHouse(
            herrs
        ),
        averagePurPowerHouseFemale: averagePurPowerHouse(
            fraus
        ),
        averagePurPowerHouseOther: averagePurPowerHouse(
            others
        ),
        averageIndexPowerPersonMale: averageIndexPowerPerson(
            herrs
        ),
        averageIndexPowerPersonFemale: averageIndexPowerPerson(
            fraus
        ),
        averageIndexPowerPersonOther: averageIndexPowerPerson(
            others
        ),
        averageIndexPowerHouseMale: averageIndexPowerHouse(
            herrs
        ),
        averageIndexPowerHouseFemale: averageIndexPowerHouse(
            fraus
        ),
        averageIndexPowerHouseOther: averageIndexPowerHouse(
            others
        ),
        averagePopulationHouseholdMale: averagePopulationHousehold(
          herrs
        ),
        averagePopulationHouseholdFemale: averagePopulationHousehold(
          fraus
        ),
        averagePopulationHouseholdOther: averagePopulationHousehold(
          others
        ),
        averagePurPowerPersonMaleMonth: averagePurPowerPerson(
            herrs
                .map((e) => {
                    let ac = { ...e };
                    if (ac.pur_power_person) {
                        ac.pur_power_person = ac.pur_power_person / 12;
                    }
                    return ac;
                })
        ),
        averagePurPowerPersonFemaleMonth: averagePurPowerPerson(
            fraus
                .map((e) => {
                    let ac = { ...e };
                    if (ac.pur_power_person) {
                        ac.pur_power_person = ac.pur_power_person / 12;
                    }
                    return ac;
                })
        ),
        averagePurPowerPersonOtherMonth: averagePurPowerPerson(
            others
                .map((e) => {
                    let ac = { ...e };
                    if (ac.pur_power_person) {
                        ac.pur_power_person = ac.pur_power_person / 12;
                    }
                    return ac;
                })
        ),
        averagePurPowerPersonMonth: averagePurPowerPerson(
            hfData
                .map((e) => {
                    let ac = { ...e };
                    if (ac.pur_power_person) {
                        ac.pur_power_person = ac.pur_power_person / 12;
                    }
                    return ac;
                })
        ),
        averagePurPowerHouseMaleMonth: averagePurPowerHouse(
            herrs
                .map((e) => {
                    let ac = { ...e };
                    if (ac.pur_power_house) {
                        ac.pur_power_house = ac.pur_power_house / 12;
                    }
                    return ac;
                })
        ),
        averagePurPowerHouseFemaleMonth: averagePurPowerHouse(
            fraus
                .map((e) => {
                    let ac = { ...e };
                    if (ac.pur_power_house) {
                        ac.pur_power_house = ac.pur_power_house / 12;
                    }
                    return ac;
                })
        ),
        averagePurPowerHouseOtherMonth: averagePurPowerHouse(
            others
                .map((e) => {
                    let ac = { ...e };
                    if (ac.pur_power_house) {
                        ac.pur_power_house = ac.pur_power_house / 12;
                    }
                    return ac;
                })
        ),
        averagePurPowerHouseMonth: averagePurPowerHouse(
            hfData
                .map((e) => {
                    let ac = { ...e };
                    if (ac.pur_power_house) {
                        ac.pur_power_house = ac.pur_power_house / 12;
                    }
                    return ac;
                })
        ),
        largestCityMale: Object.keys(citiesStat["male"]).reduce(
          function (a, b) {
            return citiesStat["male"][a] > citiesStat["male"][b]
              ? a
              : b;
          }
        ),
        largestCityFemale: Object.keys(citiesStat["female"]).reduce(
          function (a, b) {
            return citiesStat["female"][a] > citiesStat["female"][b]
              ? a
              : b;
          }
        ),
        largestCityOther: Object.keys(citiesStat["other"]).reduce(
          function (a, b) {
            return citiesStat["other"][a] > citiesStat["other"][b]
              ? a
              : b;
          }
        ),
        largestCity: Object.keys(citiesStatAll).reduce(function (a, b) {
          return citiesStatAll[a] > citiesStatAll[b] ? a : b;
        })
    };
    return result;
};