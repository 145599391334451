import { ReactNode } from "react";
import styles from "./styles.module.scss";

interface IProps {
  onClose: () => void;
  title: string;
  children: ReactNode;
}

const Modal = ({ onClose, title, children }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.modal}>
        <div className={styles.modalHeader}>
          <h2 className={styles.modalTitle}>{title}</h2>
          <button className={styles.closeButton} onClick={onClose}>
            &times;
          </button>
        </div>
        <div className={styles.modalBody}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
