import UserData from '../types/userdata.type';
import { columnsModal } from "./columns"; 
import { processData } from "../utils/dashboardUtils";

export const onChangeImportModalData = async (
    setModalFieldValue: (value: UserData[]) => void,
    setUseModalColumns: (columnsModal: any[]) => void,
    setIsDataLoaded: (value: boolean) => void,
    setUseImportDB: (value: boolean) => void
) => {
    try {
        const { mergedData } = await processData();
        setUseModalColumns(columnsModal);
        setIsDataLoaded(true);
        setUseImportDB(false);
        setModalFieldValue(mergedData);
    } catch (error) {
        const err = error as any;
        const errorMessage =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
        console.error(errorMessage);
    }
};
