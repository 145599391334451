// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__zsxx8 {
  padding: 40px 40px;
  margin: 0;
  border: none;
  box-shadow: none;
}

.styles_content__7hscR {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.styles_header__CmIJP {
  text-align: center;
}

.styles_noteText__OLmk6 {
  width: 600px;
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/components/OptComponents/UnsubscribeContact/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,SAAA;EACA,YAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,YAAA;EACA,cAAA;AACF","sourcesContent":[".wrapper {\n  padding: 40px 40px;\n  margin: 0;\n  border: none;\n  box-shadow: none;\n}\n\n.content {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.header {\n  text-align: center;\n}\n\n.noteText {\n  width: 600px;\n  margin: 0 auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__zsxx8`,
	"content": `styles_content__7hscR`,
	"header": `styles_header__CmIJP`,
	"noteText": `styles_noteText__OLmk6`
};
export default ___CSS_LOADER_EXPORT___;
