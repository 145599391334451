
import React, { useState, useEffect } from "react";

import { getPublicContent } from "../../services/user.service";

const NotFound: React.FC = () => {

const [content, setContent] = useState<string>("");

  useEffect(() => {
    getPublicContent().then(
      (response: any) => {
        setContent(response.data);
      },
    );
  }, []);

  return (
    <div className="container">
      <header className="jumbotron">
      <h3>Page Not Found</h3>
      </header>
      <div>
        {content && (
            <div>
              {content}
            </div>
          )}
      </div>
      <div>
        <p>The page you are looking for does not exist.</p>
      </div>
    </div>
  );
};

export default NotFound;
