import { Field, FieldAttributes } from "formik";
import styles from "./styles.module.scss";
import { ReactNode } from "react";

interface IProps extends FieldAttributes<any>{
  children: ReactNode
}

const SelectField = ({ children, ...props }: IProps) => {
  return (
    <Field as="select" className={styles.select} {...props}>
      {children}
    </Field>
  );
};

export default SelectField;