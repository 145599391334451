import * as Yup from "yup";

const uploadListValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Name must be at least 3 characters")
    .max(24, "Name must be at most 24 characters")
    .required("Name is required"),

  deliverer: Yup.string()
    .min(3, "Deliverer must be at least 3 characters")
    .max(24, "Deliverer must be at most 24 characters")
    .required("Deliverer is required"),

  file: Yup.array().required(),
});

export default uploadListValidationSchema;
