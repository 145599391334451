import { listTitles, formatListElementData } from "../../helpers";
import { IList } from "../../../../../types/UploadCenter";
import styles from "./styles.module.scss";

import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { uploadCenterService } from "../../../../../services/uploadCenter";
import { QUERY_KEYS } from "../../../../../common/queryKeys";
import { Link } from "react-router-dom";

import { ReactComponent as ReOrderSVG } from "../../../../../assets/icons/reOrder.svg";
import { ReactComponent as DropDownSVG } from "../../../../../assets/icons/dropDown.svg";
import { ReactComponent as LoadMoreSVG } from "../../../../../assets/icons/loadMore.svg";
import { useSortUploadLists } from "../../../../../customHooks/useSortUploadLists";
import { convertTitleIntoSortQuery } from "../../../../../utils/Strings";
import DeleteListModal from "../DeleteListModal";
import { useState } from "react";

const sortAvailableColumns = ["Name", "Contacts", "Created at", "Updated at"];

const ListElement = ({
  list,
  listId,
  showActionButtons = false,
}: {
  list: string[];
  listId?: number;
  showActionButtons?: boolean;
}) => {
  const queryClient = useQueryClient();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  const { sortQuery, order, toggleSort } = useSortUploadLists();

  const { mutate: deleteFunc } = useMutation({
    mutationFn: uploadCenterService.deleteList,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.getAllUploadedLists],
      });
    },
  });

  const toggleDeleteModal = () => setIsOpenDeleteModal((prev) => !prev);
  const deleteList = () => deleteFunc(listId!);

  return (
    <li className={styles.liElement}>
      <ul className={styles.ulProperties}>
        {list.map((i, idx) => {
          const isSortable =
            !showActionButtons && sortAvailableColumns.includes(i);
          const selectedSortQuery = sortQuery === convertTitleIntoSortQuery(i);
          const shouldRotateDropdown = selectedSortQuery && order === "ASC";

          return (
            <li
              key={`list-elem-${idx}`}
              className={`${isSortable ? styles.pointer : ""} ${
                selectedSortQuery ? styles.activeSort : ""
              }`}
              onClick={isSortable ? () => toggleSort!(i) : () => {}}
            >
              {idx === 0 && !showActionButtons && (
                <span className={styles.reOrderIconWrapper}>
                  <ReOrderSVG />
                </span>
              )}
              {i}
              {isSortable && (
                <span className={shouldRotateDropdown ? styles.rotate : ""}>
                  <DropDownSVG />
                </span>
              )}
            </li>
          );
        })}
        {showActionButtons && (
          <li>
            <Link to={`/uploadedlist/${listId}`}>
              <IconButton
                edge="end"
                aria-label="find"
                className={styles.findIcon}
              >
                <SearchRoundedIcon className={styles.icon} />
              </IconButton>
            </Link>

            <Link to={`/uploadlist/${listId}`}>
              <IconButton
                edge="end"
                aria-label="loadMore"
                className={styles.loadMoreIcon}
              >
                <LoadMoreSVG />
              </IconButton>
            </Link>
            
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={toggleDeleteModal}
              className={styles.deleteIcon}
            >
              <DeleteOutlineIcon className={styles.icon} />
            </IconButton>
          </li>
        )}
        {isOpenDeleteModal && (
          <DeleteListModal
            toggleModal={toggleDeleteModal}
            deleteList={deleteList}
          />
        )}
      </ul>
    </li>
  );
};

const ListOfLists = ({ lists }: { lists: IList[] }) => {
  return (
    <ul className={styles.listOfLists}>
      <ListElement list={listTitles} />
      {lists.map((i, idx) => (
        <ListElement
          listId={i.id}
          key={`list-${idx}-id-${i.id}`}
          list={formatListElementData(i)}
          showActionButtons
        />
      ))}
    </ul>
  );
};

export default ListOfLists;
