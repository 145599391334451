export const getAge = (birthDateString: string) => {
    // Parse the birthdate
    const [year, month, day] = birthDateString.split('.').map(part => parseInt(part, 10));

    // Create a date object for the birthdate
    const birthDate = new Date(year, month - 1, day); // Month is 0-indexed in JavaScript Date

    // Get the current date
    const currentDate = new Date();

    // Calculate the age
    let age = currentDate.getFullYear() - birthDate.getFullYear();
    const m = currentDate.getMonth() - birthDate.getMonth();

    // Adjust the age if the current month and day are before the birth month and day
    if (m < 0 || (m === 0 && currentDate.getDate() < birthDate.getDate())) {
        age--;
    }

    return age;
};