export const options = [
    { value: "name", label: "Name" },
    { value: "title", label: "Title" },
    { value: "date", label: "Date of birth" },
    { value: "zipcode", label: "Zip code" },
    { value: "city", label: "City" },
    { value: "email", label: "Email" }
];

export const optionsConvert = [
    { value: "name", label: "Convert name" },
    { value: "title", label: "Convert title" },
    { value: "date", label: "Convert date" },
    { value: "email", label: "Convert email" },
    { value: "zipcode", label: "Convert zip" },
    { value: "city", label: "Convert city" }
];

