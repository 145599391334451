import UserData from '../types/userdata.type';
import * as Yup from "yup";

export const initialValues = {
    file: { file: null },
    form: { file: [], selectOptions: [] },
    data: { selectOptions: [] },
    fileCod: { file: null, sourceCodec: '', targetCodec: 'utf-8' },
    newFields: [{ name: '', value: '' }]  // Allow multiple new fields
};

export const dataValidationSchema = Yup.object().shape({
    selectOptions: Yup.array().of(Yup.string()).required()
});

export const validationSchema = Yup.object().shape({
    file: Yup.array().of(Yup.mixed()).required(),
    selectOptions: Yup.array().of(Yup.string()).required()
});

export const applyMapping = (fieldValue: UserData[], newFields: { name: string, value: string }[]): UserData[] => {
    return fieldValue.map((row) => {
        const mappedRow: UserData = { ...row }; // Use existing fields as default

        newFields.forEach(({ name, value }) => {
            if (name && value) {
                //@ts-ignore
                mappedRow[name as keyof UserData] = value as any;
            }
        });

        return mappedRow;
    });
};

export const applyColumnMapping = (
    fieldValue: UserData[],
    newFields: { name: string, value: string }[],
    setUseMapColumns: (columnsMap: any) => void,
    setMappedFieldValue: (data: UserData[]) => void,
    setShowColumnMapping: (show: boolean) => void
) => {
    const mappedData = applyMapping(fieldValue, newFields);
    newFields.forEach(({ name }) => {
        if (name) {
            setUseMapColumns((prevColumns: any) => {
                const updatedColumns = [...prevColumns];
                updatedColumns[0].columns.push({ Header: name, accessor: name });
                return updatedColumns;
            });
        }
    });
    setMappedFieldValue(mappedData);
    setShowColumnMapping(false);
};

export const enhanceFieldValue = (fieldValue: UserData[]) => {
    return fieldValue.map((row) => ({
        ...row,
        countryCode: row.countryCode,
        cityAPI: row.cityAPI,
        zip: row.zip,
    }));
};

export const mergeData = (newData: UserData[], fieldValue: UserData[], setFieldValue: any) => {
    let updatedData = [...fieldValue];
    newData.forEach(newItem => {
        const index = updatedData.findIndex(item => item.emailaddress === newItem.emailaddress);
        if (index !== -1) {
            updatedData[index] = { ...updatedData[index], ...newItem };
        } else {
            updatedData.push(newItem);
        }
    });
    setFieldValue(updatedData);
};
