import { getIPData, getFile } from '../services/upload.service';
export const fetchLocationData = async (ip: string) => {
    try {
        const response = await getIPData(ip);

        if (response.ok) {
            const data = await response.json();
            return {
                countryCode: data.countryCode,
                cityAPI: data.city,
                zip: data.zip,
                lat: data.lat,
                lon: data.lon
            };
        } else {
            console.error("Failed to fetch IP location data");
            return {};
        }
    } catch (error) {
        console.error("Error:", error);
        return {};
    }
};

export const handleFileUpload = async (file: File, sourceCodec: string, targetCodec: string): Promise<void> => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('sourceCodec', sourceCodec);
    formData.append('targetCodec', targetCodec);

    try {
        const response = await getFile(formData);

        if (response.ok) {
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'converted.csv';
            a.click();
        } else {
            console.error('Failed to convert file');
        }
    } catch (error) {
        console.error('Error:', error);
    }
};
