import React, { useState } from 'react';
import { getContacts, getContactsByEmail } from '../../services/contacts.service';
import TableContent from '../FunctionalComponents/TableContent';

const API_URL = process.env.REACT_APP_API_URL;

const Contacts: React.FC = () => {
    const [contacts, setContacts] = useState<any[]>([]);
    const [contact, setContact] = useState<any | null>(null);
    const [email, setEmail] = useState<string>('');
    const [error, setError] = useState<string>('');

    const initialColumns = [
        { Header: 'Email', accessor: 'email' },
        { Header: 'Created', accessor: 'created' },
        { Header: 'Updated', accessor: 'updated' },
        { Header: 'ID', accessor: 'id' },
    ];

    const contactsColumns = initialColumns;
    const contactColumns = initialColumns;

    const fetchAllContacts = async () => {
        try {
            const response = await getContacts();
            setContacts(response);
            setError('');
        } catch (err) {
            setError('Error fetching contacts');
            setContacts([]);
        }
    };

    const fetchContactByEmail = async () => {
        try {
            const response = await getContactsByEmail(email);
            if (typeof response === 'string') {
                setError(response);
                setContact(null);
            } else {
                setContact(response);
                setError('');
            }
        } catch (err) {
            setError('Error fetching contact');
            setContact(null);
        }
    };

    return (
        <div>
            <h3 className="login-header">Contacts</h3>
            <div>
                <button onClick={fetchAllContacts} className="btn btn-submit btn-block">Get All Contacts</button>
            </div>
            {contacts.length > 0 && (
                <div className="table-container">
                <h3 className="login-header header-top">All Contacts</h3>
                <TableContent columns={contactsColumns} data={contacts.slice(0, 10)} />
            </div>
            )}
            <div>
                <input 
                    type="text" 
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter email to search"
                    className="form-control"
                />
                <button onClick={fetchContactByEmail} className="btn btn-submit btn-block">Get Contact By Email</button>
            </div>
            {error && (
                <div>
                    <p style={{ color: 'red' }}>{error}</p>
                </div>
            )}
            {contact && (
                <div>
                    <h3>Contact Details:</h3>
                    <TableContent columns={contactColumns} data={[contact]} />
                </div>
            )}
        </div>
    );
};

export default Contacts;
