import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

export const getIpData = (ips: string[]) => {
    // Convert the array to a comma-separated string
    const ipAddressesString = ips.join(',');
    //@ts-ignore
    return axios.get(API_URL + `process-ips?ipAddresses=${ipAddressesString}`, { headers: authHeader() });
};