import { parseCSV, parseNewCSV } from "./parseCsv";
import { formateDateTimeLocale } from "./formateDate";
import Papa from "papaparse";
import UserData from '../types/userdata.type';
import CSVRowData from '../types/csvrowdata.type';
import { CSVRowDataWithClick } from '../types/csvrowclick.type';
import { fieldMapping } from "./csvtable";

export const generateCSVWithSelectedRow = (selectedRow: CSVRowDataWithClick, selectedFields: string[]) => {
    const filteredRow = selectedFields.reduce((acc, field) => {
        const csvField = fieldMapping[field];
        if (csvField && selectedRow[field] !== undefined) {
            acc[csvField] = selectedRow[field];
        }
        return acc;
    }, {} as any);
    const csv = Papa.unparse([filteredRow], { delimiter: ";" });
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `${formateDateTimeLocale(new Date())}_selected_row_data.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const parseFile = async (file: File, setFieldValue: (value: UserData[]) => void, columns: any, setUseColumns: (columns: any) => void) => {
    const fileReader = new FileReader();
    fileReader.readAsText(file, "UTF-8");
    fileReader.onload = async (e) => {
        const csvData = await parseCSV(e.target!.result as string);
        setFieldValue(csvData as UserData[]);
        setUseColumns(columns);
    };
}
export const parseCsvFile = async (file: File, setCsvData: (value: CSVRowData[]) => void) => {
    const fileReader = new FileReader();
    fileReader.readAsText(file, "UTF-8");
    fileReader.onload = async (e) => {
        const csvData = await parseNewCSV(e.target!.result as string);
        setCsvData(csvData as CSVRowData[]);
    };
}

export const convertData = (
    fieldValue: UserData[],
    useConvName: boolean,
    useConvDate: boolean,
    useConvTitle: boolean,
    columnsConverted: any,
    setFieldConvValue: (value: UserData[]) => void,
    setUseConvColumns: (columns: any) => void
) => {
    let newArr: any = [];
    fieldValue.forEach((item: UserData) => {
        const dateB = item.birthdate?.split('.').join('-');
        const newName = useConvName ? `${item.firstname} ${item.lastname}` : item.firstname + ' ' + item.lastname;
        let newTitle = '';
        switch (item.title) {
            case 'Mr':
                newTitle = 'Herr';
                break;
            case 'Mrs':
                newTitle = 'Frau';
                break;
            default:
                newTitle = 'Diverse';
        }
        const convertedItem: any = {
            email: item.emailaddress,
            Geburtsdatum: useConvDate ? dateB : item.birthdate,
            name: newName,
            title: useConvTitle ? newTitle : item.title,
            plz: item.zipcode,
            ort: item.city
        };
        newArr.push(convertedItem);
    });
    setFieldConvValue(newArr);
    setUseConvColumns(columnsConverted);
}

export const generateCSV = (
    fieldValue: any[],
    useDate: boolean,
    useName: boolean,
    useTitle: boolean,
    useEmail: boolean,
    useZip: boolean,
    useCity: boolean
) => {
    let newArr: any = [];
    fieldValue.map((item: any) => {
        item = {
            ...(useDate && { Geburtsdatum: item.birthdate }),
            ...(useName && { firstname: item.firstname, lastname: item.lastname }),
            ...(useTitle && { title: item.title }),
            ...(useEmail && { emailaddress: item.emailaddress }),
            ...(useZip && { zipcode: item.zipcode }),
            ...(useCity && { city: item.city })
        }
        newArr.push(item);
    });
    const csv = Papa.unparse(newArr, { delimiter: ";" });
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `${formateDateTimeLocale(new Date())}_export-data.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const generateCSVWithDynamicHeaders = (data: UserData[], columns: any[]) => {
    const headers = columns[0].columns;
    const csvData = data.map((row: any) => {
        const csvRow: any = {};
        headers.forEach((header: any) => {
            csvRow[header.Header] = row[header.accessor];
        });
        return csvRow;
    });

    const csv = Papa.unparse(csvData, { delimiter: ";" });
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `${formateDateTimeLocale(new Date())}_mapped-data.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

