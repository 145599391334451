import { useQuery } from "@tanstack/react-query";
import { uploadCenterService } from "../../../services/uploadCenter";
import { QUERY_KEYS } from "../../../common/queryKeys";
import { useParams } from "react-router-dom";
import Content from "./content";
import { adminSettingsService } from "../../../services/admin_settings";

const UploadList = () => {
  const { id } = useParams();

  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.getOneUploadedList, id],
    queryFn: () => uploadCenterService.getOneList(+id!),
    enabled: !!id,
  });

  const { data: adminSettings, isLoading: isSettingsLoading } = useQuery({
    queryKey: [QUERY_KEYS.getAdminSettings],
    queryFn: adminSettingsService.getAdminSettings,
  });

  if ((id && isLoading && !data) || (isSettingsLoading && !adminSettings)) {
    return <div>Loading ...</div>;
  }

  return id ? (
    <Content data={data} adminSettings={adminSettings!} />
  ) : (
    <Content adminSettings={adminSettings!} />
  );
};

export default UploadList;
