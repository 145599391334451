import styles from "./styles.module.scss";
import { IListRow } from "../../../../types/UploadCenter";
import UploadedRowDisplay from "../../UploadedList/components/UploadedRowDisplay";

const mapObject = {
  email: "Email",
  firstName: "First name",
  lastName: "Last name",
  title: "Title",
  birthDate: "Birth date",
  zipCode: "Zip code",
  city: "City",
  streetAddress: "Street address",
  deliverer: "Deliverer",
  createdAt: "Created at",
  updatedAt: "Updated at",
};

const PreviewListTable = ({ rows }: { rows: IListRow[] | null }) => {
  const displayList = rows && rows?.length > 10 ? rows.slice(0, 10) : rows;

  return (
    <div>
      <div className={styles.listWrapper}>
        {displayList && displayList.length > 0 ? (
          <table>
            <thead className={`${styles.tableWrapper} ${styles.tableHeader}`}>
              <tr>
                {Object.keys(displayList[0]).map((column) => (
                  <th key={`headers-${column}`}>{column}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {displayList.map((row) => {
                return (
                  <UploadedRowDisplay
                    key={`view-row-${row.id}`}
                    row={row}
                    mapObject={mapObject}
                    deleteFunction={() => {}}
                    editFunction={() => {}}
                    isPreview
                  />
                );
              })}
            </tbody>
          </table>
        ) : (
          <div>No data saved in the list</div>
        )}
      </div>

      <p className={styles.lengthInfo}>
        <span>{rows?.length || 0}</span> Total contacts in this file
      </p>
    </div>
  );
};

export default PreviewListTable;
