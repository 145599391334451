export const renderInfoBlock = (icon: JSX.Element, label: string, value: any) => (
    <div className="block-info">
        {icon}
        <div className="info-style">
            {label}
            {value && <div>{value}</div>}
        </div>
    </div>
);

export const renderPPItem = (label: string, value: any) => (
    <div className="pp-item">
        <span>{label}:</span>
        <span className="info-content">
            <p>{value && <span>{value}</span>}</p>
        </span>
    </div>
);

export const renderAverageContainer = (title: string, icon: JSX.Element, age: number, ppi: number, ppm: number, ppy: number, pphm: number, pphy: number, hs: number, city: string, name?: string) => (
    <div className="average-container">
        <h4 className="heatmap-title">{title}</h4>
        <div className="heatmap-block">
            {icon}
            <div>
                {name && renderPPItem("Name", name)}
                {renderPPItem("Age", age)}
                {renderPPItem("PP Index", ppi)}
                {renderPPItem("PP Person/month", ppm)}
                {renderPPItem("PP Person/year", ppy)}
                {renderPPItem("PP Household/month", pphm)}
                {renderPPItem("PP Household/year", pphy)}
                {renderPPItem("House size", hs)}
                {renderPPItem("Lives in", city)}
            </div>
        </div>
    </div>
);