import { IList, IListRow } from "../../types/UploadCenter";
import { apiService } from "./../global/index";

class UploadCenterService {
  async getAllLists(
    search: string = "",
    sortQuery: string = "updatedAt",
    sortOrder: string = "DESC",
    limit: number = 10,
    page: number = 1
  ) {
    return (await apiService.get(
      `upload_list/all?search=${search}&sort=${sortQuery}&order=${sortOrder}&limit=${limit}&page=${page}`
    )) as { data: IList[]; page: number; total: number; totalPages: number };
  }

  async getOneList(id: number, search: string = "", limit: number = 10, page: number = 1) {
    return (await apiService.get(
      `upload_list/${id}?search=${search}&limit=${limit}&page=${page}`
    )) as {
      list: Exclude<IList, "rows">;
      rows?: IListRow[];
      page: number;
      total: number;
      totalPages: number;
    };
  }

  async createList(body) {
    return await apiService.post("upload_list/create", body, true);
  }

  async deleteList(id: number) {
    return await apiService.delete(`upload_list/${id}`);
  }

  async editList(id: number, body: { name: string }) {
    return await apiService.put(`upload_list/${id}`, body);
  }

  async loadFileToTheList(id: number, body) {
    return await apiService.post(`upload_list/${id}/upload`, body, true);
  }

  async removeListRow(listId: number, rowId: number) {
    return await apiService.delete(`upload_list/${listId}/${rowId}`);
  }

  async editListRow(listId: number, rowId: number, body: Partial<IListRow>) {
    return await apiService.put(`upload_list/${listId}/${rowId}`, body);
  }
}

export const uploadCenterService = new UploadCenterService();
