import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

export const getIPData = (ip) => {
    return fetch(`${API_URL}ip-location?ip=${ip}`);
};

export const getFile = (formData) => {
    return fetch(`${API_URL}file/upload`, {
        method: 'POST',
        body: formData,
    })
};