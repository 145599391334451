import React, { useState, useEffect } from "react";

import { getPublicContent } from "../../services/user.service";

const Home: React.FC = () => {
  const [content, setContent] = useState<string>("");

  useEffect(() => {
    getPublicContent().then(
      (response: any) => {
        setContent(response.data);
      },
    );
  }, []);

  return (
    <div className="container">
      <header className="jumbotron">
        <h3>{content}</h3>
      </header>
      <div>
        {content && (
            <div>
              {content}
            </div>
          )}
      </div>
      Public data
    </div>
  );
};

export default Home;