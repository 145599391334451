import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { QUERY_KEYS } from "../../../common/queryKeys";
import { uploadCenterService } from "../../../services/uploadCenter";
import styles from "./styles.module.scss";
import { useState } from "react";
import { IListRow } from "../../../types/UploadCenter";
import UploadedRowDisplay from "./components/UploadedRowDisplay";
import UploadedRowEdit from "./components/UploadedRowEdit";
import ReactPaginate from "react-paginate";
import { ReactComponent as ReOrderSVG } from "../../../assets/icons/reOrder.svg";
import { Field, Form, Formik } from "formik";
import Pagination from "../../FunctionalComponents/Pagination";

const mapObject = {
  deliverer: "Deliverer",
  email: "Email",
  gender: "Gender",
  firstName: "First name",
  lastName: "Last name",
  birthDate: "Birth date",
  streetAddress: "Street address",
  houseNumber: "House number",
  zipCode: "Zip code",
  city: "City",
  country: "Country",
  countryPhoneCode: "Country phone code",
  phoneAreaCode: "Phone area code",
  phoneNumber: "Phone number",
  SOI_Date: "SOI Date",
  SOI_Ip: "SOI Ip",
  SOI_UserAgent: "SOI User agent",
  DOI_Date: "DOI Date",
  DOI_Ip: "DOI Ip",
  DOI_UserAgent: "DOI User agent",
  TOI_Date: "TOI Date",
  TOI_Ip: "TOI Ip",
  TOI_UserAgent: "TOI User agent",
  isBlocked: "Is blocked",
  blockedSince: "Blocked since",
  blockedByUser: "Blocked by user",
  blockedReason: "Blocked reason",

  createdAt: "Created at",
  updatedAt: "Updated at",
};

const Content = ({ data, togglePage, selectLimit, page }) => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const [editingRowId, setEditingRowId] = useState<number | null>(null);

  const { mutateAsync: removeListRow } = useMutation({
    mutationFn: (rowId: number) =>
      uploadCenterService.removeListRow(+id!, rowId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.getOneUploadedList, id],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.getAllUploadedLists],
      });
    },
  });

  const { mutateAsync: editListRow } = useMutation({
    mutationFn: ({ rowId, body }: { rowId: number; body: Partial<IListRow> }) =>
      uploadCenterService.editListRow(+id!, rowId, body),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.getOneUploadedList, id],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.getAllUploadedLists],
      });
    },
  });

  const saveRowEditing = (rowId: number) => async (body: Partial<IListRow>) => {
    await editListRow({ rowId, body });
    setEditingRowId(null);
  };

  return (
    <>
      <div className={styles.wrapTable}>
        <table>
          <thead className={`${styles.tableWrapper} ${styles.tableHeader}`}>
            <tr>
              {Object.values(mapObject).map((column, idx) => (
                <th key={`headers-${column}`}>
                  {idx === 0 && (
                    <span className={styles.reOrderIconWrapper}>
                      <ReOrderSVG />
                    </span>
                  )}
                  {column}
                </th>
              ))}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.rows.map((row) => {
              const isEditing = row.id === editingRowId;

              return isEditing ? (
                <UploadedRowEdit
                  key={`edit-row-${row.id}`}
                  row={row}
                  mapObject={mapObject}
                  deleteFunction={removeListRow}
                  saveFunction={saveRowEditing(row.id)}
                />
              ) : (
                <UploadedRowDisplay
                  key={`view-row-${row.id}`}
                  row={row}
                  mapObject={mapObject}
                  deleteFunction={removeListRow}
                  editFunction={setEditingRowId}
                />
              );
            })}
          </tbody>
        </table>
      </div>

      <Pagination
        totalPages={data.totalPages}
        page={page}
        togglePage={togglePage}
        selectLimit={selectLimit}
      />
    </>
  );
};

export default Content;
