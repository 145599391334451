import { Field, useFormikContext } from "formik";
import styles from "./styles.module.scss";
import { listOfRequiredFieldsSavingListRow } from "../../../../common/constants";
import { listOfDBFields } from "./constants";
import { useEffect } from "react";

interface IProps {
  prevRow: { [key: string]: string };
  setFieldsAssignment: (value: { [key: string]: string }) => void;
}

const Content = ({ prevRow, setFieldsAssignment }: IProps) => {
  const { values } = useFormikContext<{ [key: string]: string }>();

  const fieldsToBeAssigned = listOfRequiredFieldsSavingListRow.filter(
    (elem) => !Object.values(values).includes(elem)
  );

  useEffect(() => {
    setFieldsAssignment(values);
  }, [values]);

  return (
    <>
      {!!fieldsToBeAssigned.length && (
        <>
          <h5>Required fields to be assigned: </h5>
          <p>{fieldsToBeAssigned.join(", ")}</p>
        </>
      )}
      <ul className={styles.list}>
        <li className={styles.listHeader}>
          <p>Contact field file import</p>
          <p>File preview</p>
          <p>Contact information</p>
        </li>
        {Object.entries(prevRow).map((elem, idx) => {
          const selectedValues = Object.values(values).filter(
            (val) => val && val !== values[elem[0]] && val !== "skip"
          );

          const availableOptions = [
            "skip",
            ...listOfDBFields.filter(
              (field) => !selectedValues.includes(field) && field !== "skip"
            ),
          ];

          return (
            <li key={`field-${idx}`}>
              <p>{elem[0]}</p>
              <p>{String(elem[1])}</p>
              <Field as="select" name={elem[0]}>
                {availableOptions.map((i) => (
                  <option key={i} value={i}>
                    {i}
                  </option>
                ))}
              </Field>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default Content;
