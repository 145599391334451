import React, { useState, useEffect, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import { getUserBoard } from "../../services/user.service";
import Modal from "../Modal/Modal";
import ImportModalDataContent from "../Modal/ImportModalDataContent";
import TableContent from "../FunctionalComponents/TableContent";
import {
  parseFile,
  parseCsvFile,
  convertData,
  generateCSV,
  generateCSVWithDynamicHeaders,
} from "../../utils/processFileData";
import {
  columns,
  columnsMap,
  columnsConverted,
  columnsModal,
} from "../../utils/columns";
import { options, optionsConvert } from "../../utils/options";
import {
  FileUploadForm,
  ConvertDataForm,
  GenerateCSVForm,
} from "../FunctionalComponents/FormikSetup";
import { FormikHelpers } from "formik";
import UserData from "../../types/userdata.type";
import FormValues from "../../types/formvalue.type";
import DataProcessingFormValues from "../../types/dataprocess.type";
import { UploadForm } from "../FunctionalComponents/UploadForm";
import { handleFileUpload as fileUpload } from "../../utils/fileUtils";
import { onChangeImportModalData } from "../../utils/dataUtils";
import {
  applyColumnMapping,
  initialValues,
  dataValidationSchema,
  validationSchema,
  enhanceFieldValue,
  mergeData,
} from "../../utils/uploadCenterUtils";
import ColumnMapping from "../FunctionalComponents/ColumnMapping";
import MailingName from "../FunctionalComponents/MailingName";
import Contacts from "../FunctionalComponents/Contacts";
import Mailing_K from "../FunctionalComponents/MailingK";
import CSVTable from "../FunctionalComponents/CSVTable";
import { generateCSVColumns } from "../../utils/generateColumns";
import {
  generateColumnsWithActions,
  generateEditableData,
  generateCustomHeaders,
} from "../FunctionalComponents/UploadFuncTools";
import { CSVRowDataWithClick } from "../../types/csvrowclick.type";
import { initialRowData } from "../../utils/csvtable";

const UploadCenterOld = () => {
  const navigate = useNavigate();
  const [content, setContent] = useState<string>("");
  const [successful, setSuccessful] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [fieldValue, setFieldValue] = useState<UserData[]>([]);
  const [modalFieldValue, setModalFieldValue] = useState<UserData[]>([]);
  const [mappedFieldValue, setMappedFieldValue] = useState<UserData[]>([]);
  const [lastUploadedData, setLastUploadedData] = useState<UserData[]>([]);
  const [fieldConvValue, setFieldConvValue] = useState<UserData[]>([]);
  const [useColumns, setUseColumns] = useState<any>(columns);
  const [useMapColumns, setUseMapColumns] = useState<any>(columnsMap);
  const [useModalColumns, setUseModalColumns] = useState<any>(columnsModal);
  const [useConvColumns, setUseConvColumns] = useState<any>(columnsConverted);
  const [useName, setUseName] = useState<boolean>(false);
  const [useTitle, setUseTitle] = useState<boolean>(false);
  const [useDate, setUseDate] = useState<boolean>(false);
  const [useEmail, setUseEmail] = useState<boolean>(false);
  const [useZip, setUseZip] = useState<boolean>(false);
  const [useCity, setUseCity] = useState<boolean>(false);
  const [useConvName, setUseConvName] = useState<boolean>(false);
  const [useConvTitle, setUseConvTitle] = useState<boolean>(false);
  const [useConvDate, setUseConvDate] = useState<boolean>(false);
  const [useConvEmail, setUseConvEmail] = useState<boolean>(false);
  const [useConvZip, setUseConvZip] = useState<boolean>(false);
  const [useConvCity, setUseConvCity] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const [useImportDB, setUseImportDB] = useState<boolean>(true);
  const [useImport, setUseImport] = useState<boolean>(true);
  const [showColumnMapping, setShowColumnMapping] = useState<boolean>(false);
  const [newFields, setNewFields] = useState<{ name: string; value: string }[]>(
    [{ name: "", value: "" }]
  );
  const [editRowIndex, setEditRowIndex] = useState<number | null>(null);
  const [editRowData, setEditRowData] = useState<UserData | null>(null);
  const [editHeaderIndex, setEditHeaderIndex] = useState<number | null>(null);
  const [editHeaderData, setEditHeaderData] = useState<string>("");
  const [csvData, setCsvData] = useState<CSVRowDataWithClick[]>([]);
  const [selectedRow, setSelectedRow] = useState<CSVRowDataWithClick | null>(
    null
  );
  const [dbFields, setDbFields] = useState<Record<string, string>>(
    Object.keys(initialRowData).reduce((acc, field) => {
      acc[field] = "Skip";
      return acc;
    }, {} as Record<string, string>)
  );

  const handleUploadFile = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      await parseCsvFile(file, (parsedData: CSVRowDataWithClick[]) => {
        setCsvData(
          parsedData.map((row) => ({
            ...row,
            onClick: () => handleRowClick(row),
          }))
        );
      });
    }
  };

  const handleRowClick = (row: CSVRowDataWithClick) => {
    setSelectedRow(row);
  };

  useEffect(() => {
    getUserBoard().then(
      (response: any) => setContent(response.data.username),
      (error: any) => {
        const _content =
          error.response?.data?.message || error.message || error.toString();
        if (_content === "Unauthorized") {
          localStorage.removeItem("user");
          navigate("/login");
          window.location.reload();
        }
        setContent(_content);
      }
    );
  }, []);

  const closeModal = () => {
    setIsModalOpen(false);
    setIsDataLoaded(false);
    setUseImportDB(true);
    setUseColumns(columns);
    setUseModalColumns(columnsModal);
  };

  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      await parseFile(
        file,
        (parsedData) => {
          setLastUploadedData(parsedData);
          mergeData(parsedData, fieldValue, setFieldValue);
        },
        columns,
        setUseColumns
      );
      setShowColumnMapping(true);
      setUseImport(false);
    }
  };

  const handleImport = (
    formValue: DataProcessingFormValues,
    formikHelpers: FormikHelpers<DataProcessingFormValues>
  ) => {
    generateCSV(
      fieldValue,
      useDate,
      useName,
      useTitle,
      useEmail,
      useZip,
      useCity
    );
    setUseName(false);
    setUseTitle(false);
    setUseDate(false);
    setMessage("message");
    setSuccessful(true);
    setMessage("Data successfully imported");
    formikHelpers.resetForm();
  };

  const startEditingRow = (index: number) => {
    setEditRowIndex(index);
    setEditRowData({ ...mappedFieldValue[index] });
  };

  const handleEditChange = (field: keyof UserData, value: string) => {
    if (editRowData) {
      setEditRowData({ ...editRowData, [field]: value });
    }
  };

  const saveEditRow = () => {
    if (editRowData && editRowIndex !== null) {
      const updatedData = [...mappedFieldValue];
      updatedData[editRowIndex] = editRowData;
      setMappedFieldValue(updatedData);
      setEditRowIndex(null);
      setEditRowData(null);
    }
  };

  const startEditingHeader = (index: number, value: string) => {
    setEditHeaderIndex(index);
    setEditHeaderData(value);
  };

  const handleHeaderChange = (value: string) => {
    setEditHeaderData(value);
  };

  const saveEditHeader = () => {
    if (editHeaderIndex !== null) {
      const updatedColumns = [...useMapColumns];
      updatedColumns[0].columns[editHeaderIndex].Header = editHeaderData;
      setUseMapColumns(updatedColumns);
      setEditHeaderIndex(null);
      setEditHeaderData("");
    }
  };

  const columnsWithActions = generateColumnsWithActions(
    useMapColumns,
    editRowIndex,
    saveEditRow,
    startEditingRow
  );

  const editableData = generateEditableData(
    mappedFieldValue,
    useMapColumns,
    editRowIndex,
    editRowData,
    handleEditChange
  );

  const customHeaders = generateCustomHeaders(
    useMapColumns,
    editHeaderIndex,
    editHeaderData,
    handleHeaderChange,
    saveEditHeader,
    startEditingHeader
  );

  const appliedColumns = () =>
    applyColumnMapping(
      fieldValue,
      newFields,
      setUseMapColumns,
      setMappedFieldValue,
      setShowColumnMapping
    );

  return (
    <div className="for-height">
      <div className="col-md-12 col-12-new">
        <div className="card card-container2 new-card">
          <h3 className="login-header">Upload center</h3>
          <button
            className="btn btn-submit btn-block"
            onClick={() => setIsModalOpen(true)}
          >
            Open Modal
          </button>
          <Modal isOpen={isModalOpen} onClose={closeModal}>
            {useImportDB ? (
              <ImportModalDataContent
                onChangeImportModalData={() =>
                  onChangeImportModalData(
                    setModalFieldValue,
                    setUseModalColumns,
                    setIsDataLoaded,
                    setUseImportDB
                  )
                }
              />
            ) : (
              isDataLoaded && (
                <TableContent
                  columns={useModalColumns}
                  data={enhanceFieldValue(modalFieldValue)}
                />
              )
            )}
          </Modal>
          {fieldValue.length > 0 && (
            <div className="table-container">
              <h3 className="login-header header-top">Initial Data</h3>
              <TableContent columns={useColumns} data={fieldValue} />
            </div>
          )}
          <FileUploadForm
            initialValues={initialValues.file}
            onChangeSaveData={handleFileUpload}
          />
          {showColumnMapping && (
            <ColumnMapping
              newFields={newFields}
              setNewFields={setNewFields}
              applyColumnMapping={appliedColumns}
            />
          )}
          {mappedFieldValue.length > 0 && (
            <div className="table-container header-top footer-item custom-table">
              <h3 className="login-header">Mapped Data</h3>
              <TableContent
                columns={columnsWithActions}
                data={editableData}
                customHeaders={customHeaders}
              />
              <button
                className="btn btn-submit btn-block"
                onClick={() =>
                  generateCSVWithDynamicHeaders(mappedFieldValue, useMapColumns)
                }
              >
                Generate Mapped Data CSV
              </button>
            </div>
          )}
          <ConvertDataForm
            initialValues={initialValues.form}
            // @ts-ignore
            validationSchema={validationSchema}
            handleConvertData={(
              formValue: FormValues,
              { resetForm }: FormikHelpers<FormValues>
            ) => {
              convertData(
                fieldValue,
                useConvName,
                useConvDate,
                useConvTitle,
                columnsConverted,
                setFieldConvValue,
                setUseConvColumns
              );
              setMessage("");
              resetForm();
            }}
            optionsConvert={optionsConvert}
            setUseConvName={setUseConvName}
            setUseConvTitle={setUseConvTitle}
            setUseConvDate={setUseConvDate}
            setUseConvEmail={setUseConvEmail}
            setUseConvZip={setUseConvZip}
            setUseConvCity={setUseConvCity}
          />
          {fieldConvValue.length > 0 && (
            <div className="table-container">
              <TableContent columns={useConvColumns} data={fieldConvValue} />
            </div>
          )}
          <GenerateCSVForm
            initialValues={initialValues.data}
            validationSchema={dataValidationSchema}
            handleImport={handleImport}
            options={options}
            setUseName={setUseName}
            setUseTitle={setUseTitle}
            setUseDate={setUseDate}
            setUseEmail={setUseEmail}
            setUseZip={setUseZip}
            setUseCity={setUseCity}
          />
          <div>
            <h3 className="login-header">CSV Codec Converter</h3>
            <UploadForm
              initialValues={initialValues.fileCod}
              handleFileUpload={fileUpload}
            />
          </div>
          <div className="App">
            <MailingName />
          </div>
          <div className="App">
            <Contacts />
          </div>
          <div className="App">
            <Mailing_K />
          </div>
          <div>
            <h3 className="login-header">CSV Data Table</h3>
            <FileUploadForm
              initialValues={initialValues.file}
              onChangeSaveData={handleUploadFile}
            />
            {csvData.length > 0 && (
              <div>
                <h3 className="login-header">CSV Data Table</h3>
                <TableContent
                  columns={generateCSVColumns(Object.keys(csvData[0]))}
                  data={csvData}
                />
              </div>
            )}
            {selectedRow && (
              <div>
                <h3 className="login-header">Selected Row Data</h3>
                <CSVTable
                  csvData={selectedRow}
                  dbFields={dbFields}
                  setDbFields={setDbFields}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadCenterOld;
