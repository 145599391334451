// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_buttonsContainer__XeUKF {
  display: flex;
  gap: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/OptComponents/UploadCenter/components/DeleteListModal/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;AACF","sourcesContent":[".buttonsContainer {\n  display: flex;\n  gap: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonsContainer": `styles_buttonsContainer__XeUKF`
};
export default ___CSS_LOADER_EXPORT___;
