import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const unsubscribeContact = async (
  email: string,
  reason: string,
  listID: number
) => {
  try {
    const endpoint =
      listID === -1
        ? `${API_URL}contacts/email/${email}/unsubscribe?reason=${encodeURIComponent(
            reason
          )}&global=true`
        : `${API_URL}contacts/email/${email}/unsubscribe?reason=${encodeURIComponent(
            reason
          )}&listID=${listID}`;
    const response = await axios.delete(endpoint);
    if (response.status === 204 || response.status === 200) {
      return { result: "Success" };
    } else {
      console.log("Failed to unsubscribe");
      return "Failed to unsubscribe";
    }
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.message) {
      return error.response.data.message;
    } else {
      return "Error unsubscribing contact";
    }
  }
};

export const batchUnsubscribeContacts = async (
  emails: string[],
  listId: number
) => {
  const results: any = [];
  for (const email of emails) {
    try {
      // Check if contact is in the list
      const checkResponse = await axios.get(
        `${API_URL}contacts/email/${email}/list/${listId}`
      );
      if (checkResponse.status === 200) {
        // Unsubscribe the contact
        const unsubscribeResponse = await axios.delete(
          `${API_URL}contacts/email/${email}/unsubscribe?reason=no_longer_interested&listID=${listId}`
        );
        if (
          unsubscribeResponse.status === 204 ||
          unsubscribeResponse.status === 200
        ) {
          results.push({ email, result: "Success" });
        } else {
          results.push({
            email,
            result: "Failed",
            message: "Failed to unsubscribe",
          });
        }
      } else {
        results.push({
          email,
          result: "Failed",
          message: "Contact not found in list",
        });
      }
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        results.push({
          email,
          result: "Failed",
          message: error.response.data.message,
        });
      } else {
        results.push({
          email,
          result: "Failed",
          message: "Error unsubscribing contact",
        });
      }
    }
  }
  return results;
};
