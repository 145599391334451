// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__3UdeB {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.styles_modal__qbRg3 {
  background-color: rgb(255, 255, 255);
  padding: 20px;
  border-radius: 8px;
  max-width: 660px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.styles_modalHeader__nwJ5f {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.styles_modalTitle__tWLIW {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
}

.styles_closeButton__S8FPb {
  background: none;
  border: none;
  font-weight: 200;
  font-size: 36px;
  cursor: pointer;
  line-height: 24px;
}

.styles_modalBody__UZVIs {
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/Common/Modal/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,oCAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;AACF;;AAEA;EACE,oCAAA;EACA,aAAA;EACA,kBAAA;EACA,gBAAA;EACA,WAAA;EACA,yCAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,SAAA;AACF;;AAEA;EACE,gBAAA;EACA,YAAA;EACA,gBAAA;EACA,eAAA;EACA,eAAA;EACA,iBAAA;AACF;;AAEA;EACE,gBAAA;AACF","sourcesContent":[".wrapper {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.5);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  z-index: 1000;\n}\n\n.modal {\n  background-color: rgb(255, 255, 255);\n  padding: 20px;\n  border-radius: 8px;\n  max-width: 660px;\n  width: 100%;\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n}\n\n.modalHeader {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 10px;\n}\n\n.modalTitle {\n  font-size: 24px;\n  font-weight: 700;\n  margin: 0;\n}\n\n.closeButton {\n  background: none;\n  border: none;\n  font-weight: 200;\n  font-size: 36px;\n  cursor: pointer;\n  line-height: 24px;\n}\n\n.modalBody {\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__3UdeB`,
	"modal": `styles_modal__qbRg3`,
	"modalHeader": `styles_modalHeader__nwJ5f`,
	"modalTitle": `styles_modalTitle__tWLIW`,
	"closeButton": `styles_closeButton__S8FPb`,
	"modalBody": `styles_modalBody__UZVIs`
};
export default ___CSS_LOADER_EXPORT___;
