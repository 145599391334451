import React from "react";
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from "formik";
import * as Yup from "yup";
import FormikReactSelect from "./FormikReactSelect";
import FileInputFormValues from "../../types/fileinput.type";
import DataProcessingFormValues from "../../types/dataprocess.type";
import FormValues from "../../types/formvalue.type";

type FileUploadFormProps = {
  initialValues: FileInputFormValues;
  onChangeSaveData: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const FileUploadForm: React.FC<FileUploadFormProps> = ({
  initialValues,
  onChangeSaveData,
}) => (
  <Formik
    initialValues={initialValues}
    onSubmit={(
      values: FileInputFormValues,
      { setSubmitting }: FormikHelpers<FileInputFormValues>
    ) => {
      setSubmitting(false);
    }}
  >
    <Form>
      <div className="form-group">
        <label htmlFor="fileName"> Select file </label>
        <Field
          id="fileName"
          name="file"
          type="file"
          className="form-control"
          onInput={onChangeSaveData}
        />
      </div>
    </Form>
  </Formik>
);

type ConvertDataFormProps = {
  initialValues: FormValues;
  validationSchema: Yup.ObjectSchema<{
    file: Yup.ArraySchema<any, any>;
    selectOptions: Yup.AnySchema;
  }>;
  handleConvertData: (
    formValue: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => void;
  optionsConvert: any;
  setUseConvName: (value: boolean) => void;
  setUseConvTitle: (value: boolean) => void;
  setUseConvDate: (value: boolean) => void;
  setUseConvEmail: (value: boolean) => void;
  setUseConvZip: (value: boolean) => void;
  setUseConvCity: (value: boolean) => void;
};

export const ConvertDataForm: React.FC<ConvertDataFormProps> = ({
  initialValues,
  validationSchema,
  handleConvertData,
  optionsConvert,
  setUseConvName,
  setUseConvTitle,
  setUseConvDate,
  setUseConvEmail,
  setUseConvZip,
  setUseConvCity,
}) => (
  <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={handleConvertData}
  >
    <Form>
      <div className="form-group">
        <p style={{ marginBottom: 5 }}> Select options for convert data </p>
        <FormikReactSelect
          name="convertdata"
          isMulti={true}
          options={optionsConvert}
          setUseName={setUseConvName}
          setUseTitle={setUseConvTitle}
          setUseDate={setUseConvDate}
          setUseEmail={setUseConvEmail}
          setUseZip={setUseConvZip}
          setUseCity={setUseConvCity}
        />
        <ErrorMessage
          name="convertdata"
          component="div"
          className="alert alert-danger"
        />
      </div>
      <div className="form-group">
        <button type="submit" className="btn btn-submit btn-block">
          Convert data
        </button>
      </div>
    </Form>
  </Formik>
);

type GenerateCSVFormProps = {
  initialValues: DataProcessingFormValues;
  validationSchema: Yup.AnyObjectSchema;
  handleImport: (
    formValue: DataProcessingFormValues,
    formikHelpers: FormikHelpers<DataProcessingFormValues>
  ) => void;
  options: any;
  setUseName: (value: boolean) => void;
  setUseTitle: (value: boolean) => void;
  setUseDate: (value: boolean) => void;
  setUseEmail: (value: boolean) => void;
  setUseZip: (value: boolean) => void;
  setUseCity: (value: boolean) => void;
};

export const GenerateCSVForm: React.FC<GenerateCSVFormProps> = ({
  initialValues,
  validationSchema,
  handleImport,
  options,
  setUseName,
  setUseTitle,
  setUseDate,
  setUseEmail,
  setUseZip,
  setUseCity,
}) => (
  <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={handleImport}
  >
    <Form>
      <div className="form-group">
        <p style={{ marginBottom: 5 }}> Select headers for csv </p>
        <FormikReactSelect
          name="selectOptions"
          isMulti={true}
          options={options}
          setUseName={setUseName}
          setUseTitle={setUseTitle}
          setUseDate={setUseDate}
          setUseEmail={setUseEmail}
          setUseZip={setUseZip}
          setUseCity={setUseCity}
        />
        <ErrorMessage
          name="selectOptions"
          component="div"
          className="alert alert-danger"
        />
      </div>
      <div className="form-group">
        <button type="submit" className="btn btn-submit btn-block">
          Generate CSV
        </button>
      </div>
    </Form>
  </Formik>
);
