interface User {
  access_token: string;
  [key: string]: any;
}

export default function authHeader() {
    const userStr = localStorage.getItem("user");
    let user: User | null = null;

    if (userStr) {
      user = JSON.parse(userStr) as User;
    }
  
    if (user && user.access_token) {
      return { Authorization: 'Bearer ' + user.access_token };
    } else {
      return {};
    }
  }