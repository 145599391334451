export const initialRowData = {
    Anrede: '',
    Vorname: '',
    Nachname: '',
    Geburtsdatum: '',
    'E_Mail': '',
    PLZ: '',
    Generierungsseite: '',
    'DOI-Timestamp': '',
    Stadt: '',
    Land: '',
    'DOI-IP': '',
    Straße: '',
    Hausnummer: '',
    'SOI-IP': '',
    'SOI-Timestamp': ''
};

export const dbFieldOptions = [
    'gender', 'firstname', 'lastname', 'birthdate', 'email', 'plz',
    'generation_page', 'doi_timestamp', 'ort', 'country', 'doi_ip',
    'street', 'building', 'soi_ip', 'soi_timestamp', 'Skip', 'Inbox-Monitor'
];

export const fieldMapping: Record<string, string> = {
    Anrede: 'gender',
    Vorname: 'firstname',
    Nachname: 'lastname',
    Geburtsdatum: 'birthdate',
    'E_Mail': 'email',
    PLZ: 'plz',
    Generierungsseite: 'generation_page',
    'DOI-Timestamp': 'doi_timestamp',
    Stadt: 'ort',
    Land: 'country',
    'DOI-IP': 'doi_ip',
    Straße: 'street',
    Hausnummer: 'building',
    'SOI-IP': 'soi_ip',
    'SOI-Timestamp': 'soi_timestamp',
    Skip: 'Skip',
    'Inbox-Monitor': 'Inbox-Monitor'
};