import Papa from "papaparse";
import jschardet from "jschardet";

export async function parseCSVRows<T>(csvData: string): Promise<T[]> {
  return new Promise((resolve, reject) => {
    Papa.parse(csvData, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        resolve(results.data as T[]);
      },
      error: (error: any) => reject(error),
    });
  });
}

function refineEncoding(detectedEncoding: string, defaultEncoding = "ISO-8859-1") {
  const supportedEncodings = ["ISO-8859-1", "UTF-8", "UTF-16"];
  if (!supportedEncodings.includes(detectedEncoding)) {
    return defaultEncoding;
  }
  return detectedEncoding;
}

export async function detectFileEncoding(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const result = reader.result;

      if (typeof result === "string") {
        const detection = jschardet.detect(result);
        resolve(refineEncoding(detection.encoding));
      } else if (result instanceof ArrayBuffer) {
        const text = new TextDecoder().decode(result);
        const detection = jschardet.detect(text);
        resolve(refineEncoding(detection.encoding));
      } else {
        reject(new Error("Unexpected result type"));
      }
    };

    reader.onerror = () => reject(new Error("Failed to read file."));
    reader.readAsBinaryString(file);
  });
}
