import { Form, Formik } from "formik";
import Content from "./content";
import { autoAssignHelper } from "./constants";

const ListRowFieldAssignments = ({
  rows,
  setFieldsAssignment,
}: {
  rows: { [key: string]: string }[];
  setFieldsAssignment: (value: { [key: string]: string }) => void;
}) => {
  const prevRow = rows[0];

  const initValues: { [key: string]: string } = Object.keys(prevRow).reduce(
    (prev, curr) => {
      const auto = Object.entries(autoAssignHelper).find((i) =>
        i[1].find((i) => curr.toLowerCase().includes(i))
      );

      return { ...prev, [curr]: auto ? auto[0] : "skip" };
    },
    {}
  );

  const submitFunction = (values: { [key: string]: string }) => {
    setFieldsAssignment(values);
  };

  return (
    <div>
      <Formik initialValues={initValues} onSubmit={submitFunction}>
        <Form>
          <Content prevRow={prevRow} setFieldsAssignment={setFieldsAssignment} />
        </Form>
      </Formik>
    </div>
  );
};

export default ListRowFieldAssignments;
