import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

export const insertData = (data: any) => {
    return axios.post(API_URL + "insertdata", data);
};

export const getUsersData = () => {
    //@ts-ignore
    return axios.get(API_URL + "getdata", { headers: authHeader() });
};