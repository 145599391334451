import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from "formik";
import * as Yup from "yup";
import FileInputFormValues from "../../types/fileinputcod.type";

type UploadFormProps = {
    initialValues: FileInputFormValues;
    handleFileUpload: (file: File, sourceCodec: string, targetCodec: string) => void;
};

const validationSchema = Yup.object().shape({
    file: Yup.mixed().required("File is required"),
    sourceCodec: Yup.string().required("Source codec is required"),
    targetCodec: Yup.string().required("Target codec is required"),
});

export const UploadForm: React.FC<UploadFormProps> = ({
    initialValues,
    handleFileUpload,
}) => {
    const [detectedCodec, setDetectedCodec] = useState<string | null>(null);

    const handleFileChange = async (
        event: React.ChangeEvent<HTMLInputElement>,
        setFieldValue: (field: string, value: any) => void
    ) => {
        if (event.currentTarget.files) {
            const file = event.currentTarget.files[0];
            setFieldValue("file", file);

            // Detect file encoding
            try {
                const formData = new FormData();
                formData.append("file", file);

                const API_URL = process.env.REACT_APP_API_URL;
                const response = await fetch(`${API_URL}file/detect-encoding`, {
                    method: "POST",
                    body: formData,
                });

                if (response.ok) {
                    const data = await response.json();
                    setDetectedCodec(data.encoding);
                    setFieldValue("sourceCodec", data.encoding);
                } else {
                    console.error("Failed to detect file encoding");
                }
            } catch (error) {
                console.error("Error:", error);
            }
        }
    };

    const handleSubmit = async (
        values: FileInputFormValues,
        { setSubmitting }: FormikHelpers<FileInputFormValues>
    ) => {
        if (values.file) {
            //@ts-ignore
            handleFileUpload(values.file, values.sourceCodec, values.targetCodec);
        }
        setSubmitting(false);
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ setFieldValue }) => (
                <Form>
                    <div className="form-group">
                        <label htmlFor="file"> Select file </label>
                        <input
                            id="file"
                            name="file"
                            type="file"
                            className="form-control"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                handleFileChange(event, setFieldValue)
                            }
                        />
                        <ErrorMessage
                            name="file"
                            component="div"
                            className="alert alert-danger"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="sourceCodec"> Source codec </label>
                        <Field name="sourceCodec" className="form-control" disabled />
                        <ErrorMessage
                            name="sourceCodec"
                            component="div"
                            className="alert alert-danger"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="targetCodec"> Select target codec </label>
                        <Field as="select" name="targetCodec" className="form-control">
                            <option value="utf-8">UTF-8</option>
                            <option value="iso-8859-1">ISO-8859-1</option>
                            <option value="utf-16">UTF-16</option>
                            {/* Add more codecs as needed */}
                        </Field>
                        <ErrorMessage
                            name="targetCodec"
                            component="div"
                            className="alert alert-danger"
                        />
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-submit btn-block">
                            Upload and Convert
                        </button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default UploadForm;
