export const getAverageName = (names: string[]): string => {
    const nameCounts: Record<string, number> = {};

    // Count each name
    for (const name of names) {
        if (nameCounts[name]) {
            nameCounts[name]++;
        } else {
            nameCounts[name] = 1;
        }
    }

    // Find the name with the highest count
    let mostFrequentName = '';
    let maxCount = 0;
    for (const [name, count] of Object.entries(nameCounts)) {
        if (count > maxCount) {
            mostFrequentName = name;
            maxCount = count;
        }
    }

    return mostFrequentName;
};