import { getAge } from "./getAge";
import { mapApiResponseToType } from "./userDataUtils";
import { getPurchasingPowerData } from "./getPurchuasingPower";
import { getUsersData } from "../services/users-data.service";
import { getMapData } from "../services/users-map.servise";
import { getZipData } from "../services/users-zip.service";
import { getIpData } from "../services/users-ip.service";
import IMap from "../types/map.type";
import IMaped from "../types/getmap.type";
import UserData from '../types/userdata.type';
import { fetchLocationData } from "./fileUtils";

type MergedData = UserData & IMaped;

export async function processData() {
  const response = await getUsersData();
  const users = response.data as UserData[];
  let countMale = 0;
  let countFemale = 0;
  let countDiverse = 0;
  let ageArray: number[] = [];
  let ageMaleArray: number[] = [];
  let ageFemaleArray: number[] = [];
  let ageDiverseArray: number[] = [];
  let ipArray: string[] = [];
  let mapArray: IMap[] = [];
  let mapDataArray: IMaped[] = [];
  let nameMaleArray: string[] = [];
  let nameFemaleArray: string[] = [];
  let nameDiverseArray: string[] = [];

  const userPromises = users.map(async (user: any) => {
    const age = getAge(user.birthdate || "");
    ageArray.push(age);
    ipArray.push(user.ip);
    if (user.title === "Mr") {
      nameMaleArray.push(user.firstname);
      ageMaleArray.push(age);
      countMale++;
    } else if (user.title === "Mrs") {
      nameFemaleArray.push(user.firstname);
      ageFemaleArray.push(age);
      countFemale++;
    } else {
      nameDiverseArray.push(user.firstname);
      ageDiverseArray.push(age);
      countDiverse++;
    }

    try {
      const responseZip = await getMapData(user.zipcode);
      const data = responseZip.data as IMaped;
      data.zipcode = user.zipcode; 
      const mappedData = mapApiResponseToType(data);
      mapDataArray.push(mappedData);
      mappedData.zipcode = user.zipcode;
      mappedData.ip = user.ip;
      mappedData.Gender = user.title === "Mr" ? "Herr" : user.title === "Mrs" ? "Frau" : "Diverse";
      mapArray.push(mappedData);
    } catch (error: any) {
      console.error("Error fetching map data:", error.message);
    }
  });

  await Promise.all(userPromises);
  const zipData = await getZipData();
  const ipData = await getIpData(ipArray);

  const purchasingPowerData = await getPurchasingPowerData(mapArray, zipData);

  const mergeUserDataWithMapedData = (
    users: UserData[],
    mapedData: IMaped[]
  ): MergedData[] => {
    const mapedDataMap = new Map<number | null | undefined, IMaped>();
  
    // Create a map for easy lookup of maped data by zipcode
    mapedData.forEach((data) => {
      mapedDataMap.set(data.zipcode, data);
    });
  
    // Merge the users with their corresponding maped data
    const mergedData = users.map((user) => {
      const mapData = mapedDataMap.get(user.zipcode || 0); 
      return {
        ...user,
        ...mapData,
      };
    });
    
    return mergedData;
  };

  const updatedUsers = await Promise.all(users.map(async (user: UserData) => {
    const ip = user.ip;
    const location = await fetchLocationData(ip);
    return { ...user, ...location };
  }));

  const mergedData = mergeUserDataWithMapedData(updatedUsers, mapDataArray);

  return {
    users,
    countMale,
    countFemale,
    countDiverse,
    ageArray,
    ageMaleArray,
    ageFemaleArray,
    ageDiverseArray,
    nameMaleArray,
    nameFemaleArray,
    nameDiverseArray,
    purchasingPowerData,
    mergedData
  };
}
