import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { QUERY_KEYS } from "../../../common/queryKeys";
import { uploadCenterService } from "../../../services/uploadCenter";
import styles from "./styles.module.scss";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { useState } from "react";
import { IListRow } from "../../../types/UploadCenter";
import UploadedRowDisplay from "./components/UploadedRowDisplay";
import UploadedRowEdit from "./components/UploadedRowEdit";
import Button from "../../Common/Button";
import Papa from "papaparse";
import { formateDateTimeLocale } from "../../../utils/formateDate";
import { ReactComponent as DownloadCsvSVG } from "../../../assets/icons/downloadCSV.svg";
import { ReactComponent as LoadMoreSVG } from "../../../assets/icons/loadMore.svg";
import { ReactComponent as ArrowBackSVG } from "../../../assets/icons/arrowBack.svg";
import { ReactComponent as ReOrderSVG } from "../../../assets/icons/reOrder.svg";

const mapObject = {
  deliverer: "Deliverer",
  email: "Email",
  gender: "Gender",
  firstName: "First name",
  lastName: "Last name",
  birthDate: "Birth date",
  streetAddress: "Street address",
  houseNumber: "House number",
  zipCode: "Zip code",
  city: "City",
  country: "Country",
  countryPhoneCode: "Country phone code",
  phoneAreaCode: "Phone area code",
  phoneNumber: "Phone number",
  SOI_Date: "SOI Date",
  SOI_Ip: "SOI Ip",
  SOI_UserAgent: "SOI User agent",
  DOI_Date: "DOI Date",
  DOI_Ip: "DOI Ip",
  DOI_UserAgent: "DOI User agent",
  TOI_Date: "TOI Date",
  TOI_Ip: "TOI Ip",
  TOI_UserAgent: "TOI User agent",
  isBlocked: "Is blocked",
  blockedSince: "Blocked since",
  blockedByUser: "Blocked by user",
  blockedReason: "Blocked reason",

  createdAt: "Created at",
  updatedAt: "Updated at",
};

const UploadedList = () => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const [editingRowId, setEditingRowId] = useState<number | null>(null);

  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.getOneUploadedList, id],
    queryFn: () => uploadCenterService.getOneList(+id!),
  });

  const { mutateAsync: removeListRow } = useMutation({
    mutationFn: (rowId: number) =>
      uploadCenterService.removeListRow(+id!, rowId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.getOneUploadedList, id],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.getAllUploadedLists],
      });
    },
  });

  const { mutateAsync: editListRow } = useMutation({
    mutationFn: ({ rowId, body }: { rowId: number; body: Partial<IListRow> }) =>
      uploadCenterService.editListRow(+id!, rowId, body),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.getOneUploadedList, id],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.getAllUploadedLists],
      });
    },
  });

  const saveRowEditing = (rowId: number) => async (body: Partial<IListRow>) => {
    await editListRow({ rowId, body });
    setEditingRowId(null);
  };

  const downloadCSV = () => {
    const csv = Papa.unparse(data?.rows!, { delimiter: ";" });

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `${data?.name}_${formateDateTimeLocale(new Date())}.csv`
    );
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (isLoading && !data) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.listWrapper}>
        <div className={styles.listHeader}>
          <div className={styles.buttonAndHeader}>
            <Link to="/uploadcenter">
              <IconButton>
                <ArrowBackSVG />
              </IconButton>
            </Link>
            <h3>{data?.name}</h3>
          </div>

          <div className={styles.buttonAndHeader}>
            {editingRowId ? (
              <Button onClick={() => setEditingRowId(null)}>
                Cancel Editing
              </Button>
            ) : (
              <Button onClick={downloadCSV} isOutline>
                <p className={styles.noBreakLine}>
                  <DownloadCsvSVG /> Download CSV
                </p>
              </Button>
            )}

            <Link to={`/uploadlist/${id}`} className={styles.noDecoration}>
              <Button>
                <p className={styles.noBreakLine}>
                  <LoadMoreSVG /> Load More
                </p>
              </Button>
            </Link>
          </div>
        </div>
        <div className={styles.wrapTable}>
          {data?.rows && data.rows.length > 0 ? (
            <table>
              <thead className={`${styles.tableWrapper} ${styles.tableHeader}`}>
                <tr>
                  {Object.values(mapObject).map((column, idx) => (
                    <th key={`headers-${column}`}>
                      {idx === 0 && (
                        <span className={styles.reOrderIconWrapper}>
                          <ReOrderSVG />
                        </span>
                      )}
                      {column}
                    </th>
                  ))}
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.rows.map((row) => {
                  const isEditing = row.id === editingRowId;

                  return isEditing ? (
                    <UploadedRowEdit
                      key={`edit-row-${row.id}`}
                      row={row}
                      mapObject={mapObject}
                      deleteFunction={removeListRow}
                      saveFunction={saveRowEditing(row.id)}
                    />
                  ) : (
                    <UploadedRowDisplay
                      key={`view-row-${row.id}`}
                      row={row}
                      mapObject={mapObject}
                      deleteFunction={removeListRow}
                      editFunction={setEditingRowId}
                    />
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div>No data saved in the list</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadedList;
