import React, { useState } from 'react';
import { getMailings } from '../../services/mailingk.service';
import TableContent from '../FunctionalComponents/TableContent';
import { MailingK } from "../../types/mailingk.type";

const Mailing_K: React.FC = () => {
    const [mailings, setMailings] = useState<MailingK[]>([]);
    const [test, setTest] = useState<any>();
    const [error, setError] = useState<string>('');

    const fetchAllMailings = async () => {
        try {
            const data = await getMailings();
            setMailings(data);
            setTest(data)
            setError('');
        } catch (err) {
            setError('Error fetching mailings');
        }
    };

    const columns = [
        { Header: 'ID', accessor: 'id' },
        { Header: 'Sender Name', accessor: 'sendername' },
        { Header: 'Subject', accessor: 'subject' },
        { Header: 'Description', accessor: 'description' },
        { Header: 'List', accessor: 'list' },
        { Header: 'Status', accessor: 'status' },
        { Header: 'Mail Type', accessor: 'mailType' },
        { Header: 'Action', accessor: 'action' },
        { Header: 'Size', accessor: 'size' },
        { Header: 'Datetime', accessor: 'datetime' },
    ];

    return (
        <div>
            <h3 className="login-header">Mailings</h3>
            <div>
                <button onClick={fetchAllMailings} className="btn btn-submit btn-block">Get All Mailings</button>
            </div>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {mailings.length > 0 && (
                <div className="table-container">
                    <h3 className="login-header header-top">All Mailings</h3>
                    <TableContent columns={columns} data={mailings.slice(0, 10)} />
                </div>
            )}
            {test && test.test}
        </div>
    );
};

export default Mailing_K;
