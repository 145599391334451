import React from "react";
import { useState, useEffect } from "react";
import { Route, Link, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import styles from "./app.styles.module.scss";

import * as AuthService from "./services/auth.service";
import IUser from "./types/user.type";

import Login from "./components/Pages/Login";
import Register from "./components/Pages/Register";
import Home from "./components/Pages/Home";
import NotFound from "./components/Pages/NotFound";
import Profile from "./components/Pages/Profile";
import BoardUser from "./components/Boards/BoardUser";
import ExportData from "./components/OldComponents/ExportData";
import ImportData from "./components/OldComponents/ImportData";
import ConvertData from "./components/OldComponents/ConvertData";
import SaveData from "./components/OldComponents/SaveData";
import Dashboards from "./components/OptComponents/Dashboards";
import LeadGenPartner from "./components/OptComponents/LeadGenPartner";
import Listmanager from "./components/OptComponents/Listmanager";
import OptInSearch from "./components/OptComponents/OptInSearch";
import SelectionExport from "./components/OptComponents/SelectionExport";
import TemplateCenter from "./components/OptComponents/TemplateCenter";
import UnsubscribeContactOld from "./components/OptComponents/UnsubscribeContactOld";
import UserManager from "./components/OptComponents/UserManager";
import BoardModerator from "./components/Boards/BoardModerator";
import BoardAdmin from "./components/Boards/BoardAdmin";
import SideBar from "./components/FunctionalComponents/Sidebar";

import EventBus from "./common/EventBus";
import UploadCenterOld from "./components/OptComponents/UploadCenterOld";
import UploadCenter from "./components/OptComponents/UploadCenter";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import UploadedList from "./components/OptComponents/UploadedList";
import UploadList from "./components/OptComponents/UploadList";
import AdminSettings from "./components/OptComponents/AdminSettings";
import UnsubscribeContact from "./components/OptComponents/UnsubscribeContact";
import { SortUploadListProvider } from "./providers/sortUploadListProvider";

const queryClient = new QueryClient();

const App: React.FC = () => {
  const [showModeratorBoard, setShowModeratorBoard] = useState<boolean>(false);
  const [showAdminBoard, setShowAdminBoard] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<IUser | undefined>(undefined);

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    if (user) {
      setCurrentUser(user);
      setShowAdminBoard(user.username.includes("admin"));
    }

    EventBus.on("logout", logOut);

    return () => {
      EventBus.remove("logout", logOut);
    };
  }, []);

  const logOut = () => {
    AuthService.logout();
    setShowModeratorBoard(false);
    setShowAdminBoard(false);
    setCurrentUser(undefined);
  };

  return (
    <div className={styles.mainContainer}>
      <QueryClientProvider client={queryClient}>
        <SortUploadListProvider>
          {currentUser && <SideBar />}
          <div className={styles.appContainer}>
            <nav className={styles.appHeader}>
              <h5>OPT-IN-CENTER</h5>

              <div className={styles.headerInfo}>
                <h5>INFO</h5>
                <div className={styles.profileBlock}></div>
              </div>
            </nav>

            <div className={styles.content}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/all" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/info" element={<BoardUser />} />
                <Route path="/exportdata" element={<ExportData />} />
                <Route path="/importdata" element={<ImportData />} />
                <Route path="/convertdata" element={<ConvertData />} />
                <Route path="/savedata" element={<SaveData />} />
                <Route path="/listmanager" element={<Listmanager />} />
                <Route path="/leadgenpartner" element={<LeadGenPartner />} />
                <Route path="/uploadcenter-old" element={<UploadCenterOld />} />
                <Route path="/uploadcenter" element={<UploadCenter />} />
                <Route path="/uploadlist" element={<UploadList />} />
                <Route path="/uploadlist/:id" element={<UploadList />} />
                <Route path="/uploadedlist/:id" element={<UploadedList />} />
                <Route path="/optinsearch" element={<OptInSearch />} />
                <Route path="/selectionexport" element={<SelectionExport />} />
                <Route path="/adminsettings" element={<AdminSettings />} />
                <Route
                  path="/unsubscribecontact-old"
                  element={<UnsubscribeContactOld />}
                />
                <Route
                  path="/unsubscribecontact"
                  element={<UnsubscribeContact />}
                />
                <Route path="/usermanager" element={<UserManager />} />
                <Route path="/templatecenter" element={<TemplateCenter />} />
                <Route path="/dashboards" element={<Dashboards />} />
                <Route path="/mod" element={<BoardModerator />} />
                <Route path="/admin" element={<BoardAdmin />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
          </div>
        </SortUploadListProvider>
      </QueryClientProvider>
    </div>
  );
};

export default App;
