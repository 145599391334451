import React from "react";
import { useTable, Column, Row } from "react-table";
import styled from "styled-components";
import { CSVRowDataWithClick } from '../../types/csvrowclick.type';

const Styles = styled.div`
  background-color: #fff;

  input {
    border: 1px solid black;
    padding: 0.4rem;
  }

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }

  .tr-click {
    text-decoration: underline;
    cursor: pointer;
  }
`;

interface TableProps<T extends object> {
  columns: Column<T>[];
  data: T[];
  customHeaders?: React.ReactNode[];
}

export function Table<T extends CSVRowDataWithClick>({
  columns,
  data,
  customHeaders
}: TableProps<T>): React.ReactElement {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable<T>(
    {
      columns,
      data
    }
  );

  const handleRowClick = (row: Row<T>) => {
    if (row.original.onClick) {
      row.original.onClick();
    }
  };

  return (
    <Styles>
      <table {...getTableProps()} className="table-body">
        <thead>
          {customHeaders ? (
            <>
              {customHeaders}
            </>
          ) : (
            headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                    {/* Render the columns filter UI */}
                  </th>
                ))}
              </tr>
            ))
          )}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr 
                {...row.getRowProps()} 
                onClick={() => handleRowClick(row)} 
                className={row.original.onClick ? 'tr-click' : ''}
              >
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Styles>
  );
}
