import { IAdminSettings } from "../../types/AdminSettings";
import { apiService } from "./../global/index";

class AdminSettingsService {
  async getAdminSettings(): Promise<IAdminSettings> {
    return await apiService.get("admin_settings");
  }

  async updateAdminSettings(data: any) {
    return await apiService.put("admin_settings", data);
  }
}

export const adminSettingsService = new AdminSettingsService();
