import { Field, Form, Formik } from "formik";
import styles from "./styles.module.scss";
import ReactPaginate from "react-paginate";

const Pagination = ({page, togglePage, selectLimit, totalPages}) => {
  return (
    <div className={styles.paginationBlock}>
      <div className={styles.paginationSide}>
        <span>Rows per page</span>
        <select
          defaultValue={10}
          onChange={(e) => selectLimit(+e.target.value)}
          className={styles.limitSelector}
        >
          {Array(16)
            .fill(0)
            .map((_, idx) => (
              <option value={idx + 5} key={`limit-select-${idx}`}>
                {idx + 5}
              </option>
            ))}
        </select>
      </div>
      <ReactPaginate
        onPageChange={togglePage}
        pageRangeDisplayed={3}
        marginPagesDisplayed={3}
        pageCount={totalPages}
        pageLinkClassName={styles.pageItemLink}
        previousClassName={styles.displayNone}
        nextClassName={styles.displayNone}
        breakLabel="..."
        breakLinkClassName={styles.pageItemLink}
        containerClassName={styles.pagesBlock}
        activeClassName={styles.activePage}
        forcePage={page}
      />
      <div className={styles.paginationSide}>
        <span>Go to page</span>
        <Formik
          initialValues={{ page: "" }}
          onSubmit={(values) => {
            togglePage({ selected: +values.page - 1 });
          }}
        >
          {({ values }) => (
            <Form>
              <Field type="number" className={styles.numberInput} name="page" />
              <button
                children=">"
                type="submit"
                className={styles.inputNumberButton}
                disabled={
                  !values.page ||
                  +values.page < 1 ||
                  +values.page > totalPages
                }
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Pagination;