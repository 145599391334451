import React, { useState } from 'react';
import { initialRowData, dbFieldOptions, fieldMapping } from '../../utils/csvtable';
import { CSVRowDataWithClick } from '../../types/csvrowclick.type';
import { generateCSVWithSelectedRow } from '../../utils/processFileData';
import axios from 'axios';

interface CSVTableProps {
    csvData: CSVRowDataWithClick;
    dbFields: Record<string, string>;
    setDbFields: React.Dispatch<React.SetStateAction<Record<string, string>>>;
}

const CSVTable: React.FC<CSVTableProps> = ({ csvData, dbFields, setDbFields }) => {
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const handleSelectChange = (field: string, value: string) => {
        setDbFields({
            ...dbFields,
            [field]: value
        });
    };

    const handleGenerateCSV = () => {
        const selectedFields = Object.keys(dbFields).filter(key => dbFields[key] !== 'Skip');
        generateCSVWithSelectedRow(csvData, selectedFields);
    };

    const API_URL = process.env.REACT_APP_API_URL;

    const handleSaveInDB = async () => {
        const selectedFields = Object.keys(dbFields).filter(key => dbFields[key] !== 'Skip');
        const processedData = selectedFields.reduce((acc, field) => {
            const dbField = dbFields[field];
            const csvField = fieldMapping[field];
            if (csvField && csvData[field] !== undefined) {
                let value = csvData[field];
                if (dbField === 'gender') {
                    if (value === 'Herr' || value === 'Mr') {
                        value = 'male';
                    } else if (value === 'Frau' || value === 'Mrs') {
                        value = 'female';
                    } else if (value === 'Mx' || value === 'X') {
                        value = 'diverse';
                    }
                }
                acc[dbField] = value;
            }
            return acc;
        }, {} as any);

        try {
            const { status } = await axios.post(`${API_URL}csv-row-data`, processedData);
            if (status === 201) {
                console.log('Data saved successfully!');
                setErrorMessage(null);
            } else {
                console.log('Failed to save data.');
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                switch (error.response.data.message) {
                    case 'Email already exists':
                    case 'Email field is required':
                    case 'You have not selected anything to save data':
                        setErrorMessage(error.response.data.message);
                        break;
                    default:
                        setErrorMessage('Error: Failed to save data.');
                }
            } else {
                setErrorMessage('Error: Failed to save data.');
            }
        }
    };

    return (
        <>
            <table className='table-state'>
                <thead>
                    <tr className='tr-header'>
                        <th>Fieldname CSV</th>
                        <th>Preview Content</th>
                        <th>Data field in DB</th>
                        <th>Status & Information</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(initialRowData).map((field, index) => (
                        <tr className='tr-row' key={index}>
                            <td>{field}</td>
                            <td>{csvData[field]}</td>
                            <td>
                                <select
                                    value={dbFields[field]}
                                    onChange={(e) => handleSelectChange(field, e.target.value)}
                                >
                                    {dbFieldOptions.map((option, optIndex) => (
                                        <option key={optIndex} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </td>
                            <td>{/* Status & Information content here */}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="form-group header-top">
                <button onClick={handleGenerateCSV} className="btn btn-submit btn-block">
                    Generate CSV
                </button>
                <button onClick={handleSaveInDB} className="btn btn-submit btn-block">
                    Save in DB
                </button>
                {errorMessage && (
                    <div className="alert alert-danger" style={{ marginTop: '10px' }}>
                        {errorMessage}
                    </div>
                )}
            </div>
        </>
    );
};

export default CSVTable;
