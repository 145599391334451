import React, { useState } from 'react';

interface ColumnMappingProps {
    newFields: { name: string, value: string }[];
    setNewFields: (fields: { name: string, value: string }[]) => void;
    applyColumnMapping: () => void;
}

const ColumnMapping: React.FC<ColumnMappingProps> = ({
    newFields,
    setNewFields,
    applyColumnMapping
}) => {
    const handleFieldChange = (index: number, field: string, value: string) => {
        const updatedFields = newFields.map((newField, i) => (
            i === index ? { ...newField, [field]: value } : newField
        ));
        setNewFields(updatedFields);
    };

    const addField = () => {
        setNewFields([...newFields, { name: '', value: '' }]);
    };

    const removeField = (index: number) => {
        setNewFields(newFields.filter((_, i) => i !== index));
    };

    return (
        <div className="footer-item">
            <h3 className="login-header">Map Columns</h3>
            {newFields.map((field, index) => (
                <div key={index} className="form-group">
                    <label>New Field Name:</label>
                    <input
                        className="form-control"
                        type="text"
                        value={field.name}
                        onChange={(e) => handleFieldChange(index, 'name', e.target.value)}
                    />
                    <label>New Field Value:</label>
                    <input
                        className="form-control"
                        type="text"
                        value={field.value}
                        onChange={(e) => handleFieldChange(index, 'value', e.target.value)}
                    />
                    <button type="button" className="btn btn-danger btn-radius header-top" onClick={() => removeField(index)}>Remove</button>
                </div>
            ))}
            <button type="button" className="btn btn-secondary btn-radius footer-item" onClick={addField}>Add Field</button>
            <button className="btn btn-submit btn-block" onClick={applyColumnMapping}>Apply Mapping</button>
        </div>
    );
};

export default ColumnMapping;
