import { useState, useEffect } from "react";
import { getUserBoard } from "../../services/user.service";
import { useNavigate } from "react-router-dom";

const OptInSearch = () => {
  const navigate = useNavigate();
  const [content, setContent] = useState<string>("");

  useEffect(() => {
    getUserBoard().then(
      (response: any) => {
        setContent(response.data.username);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        if (_content === "Unauthorized") {
          localStorage.removeItem("user");
          navigate("/login");
          window.location.reload();
        }

        setContent(_content);
      }
    );
  }, []);

  return (
    <div className="for-height">
      <div className="col-md-12 col-12-new">
        <div className="card card-container2 new-card">
          <h3 className="login-header">OPT-IN Search</h3>
          <div>
            First you choose a list, then you can select a data set by e-mail
            and download an optin proof (PDF) to it, which contains all data of
            the contact in the list inclusive.
          </div>
        </div>
      </div>
    </div>
  );
};

export default OptInSearch;
