import { useState } from "react";
import styles from "./styles.module.scss";
import { Field } from "formik";
import { IListRow } from "../../../types/UploadCenter";
import { parseCSVRows } from "../../../utils/UploadCenter";
import { ReactComponent as DragNDropSVG } from "../../../assets/icons/dragNDrop.svg";
import { ReactComponent as CrossSVG } from "../../../assets/icons/cross.svg";
import { IconButton } from "@mui/material";

interface IProps {
  value?: File | null;
  onChangeFunction: (value: IListRow[] | null) => void;
  name: string;
  saveRawFile: (value: File | null) => void;
}

const DranNDropUpload = ({ value, onChangeFunction, name, saveRawFile }: IProps) => {
  const [dragActive, setDragActive] = useState(false);
  const [fileName, setFileName] = useState<string | null>(null);

  const readAndSaveFile = (file: File) => {
    saveRawFile(file);

    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = async (e) => {
      setFileName(file.name);
      const csvData = await parseCSVRows<IListRow>(e.target!.result as string);

      onChangeFunction(csvData);
    };
  };

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      readAndSaveFile(file);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      readAndSaveFile(file);
    }
  };

  const removeFile = () => onChangeFunction(null);

  if (value)
    return (
      <div className={styles.fileName}>
        <span>{fileName}</span>
        <IconButton edge="end" aria-label="close" onClick={removeFile}>
          <CrossSVG />
        </IconButton>
      </div>
    );

  return (
    <div
      className={`${styles.dragDrop} ${dragActive ? styles.dragActive : ""}`}
      onDragEnter={handleDrag}
      onDragOver={handleDrag}
      onDragLeave={handleDrag}
      onDrop={handleDrop}
    >
      <Field
        name={name}
        id="fileUpload"
        type="file"
        className={styles.fileInput}
        onInput={handleChange}
        hidden
      />
      <label htmlFor="fileUpload" className={styles.uploadLabel}>
        <div className={styles.iconContainer}>
          <DragNDropSVG />
        </div>
        <p>Drag & Drop files here</p>
        <p className={styles.orWrapper}>
          <span className={styles.orDash}></span>
          <span>or</span>
        </p>
        <p>Click and browse</p>
      </label>
    </div>
  );
};

export default DranNDropUpload;
