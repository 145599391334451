import { DeleteOutline } from "@mui/icons-material";
import Button from "../../../../Common/Button";
import Modal from "../../../../Common/Modal";
import { ReactComponent as CrossSVG } from "../../../../../assets/icons/cross.svg";

import styles from "./styles.module.scss";

interface IProps {
  toggleModal: () => void;
  deleteList: () => void;
}

const DeleteListModal = ({ toggleModal, deleteList }: IProps) => {
  return (
    <Modal
      onClose={toggleModal}
      title="Are you sure you want to delete this list?"
    >
      <div className={styles.buttonsContainer}>
        <Button onClick={toggleModal} isOutline isRed>
          <CrossSVG /> Cancel
        </Button>
        <Button onClick={deleteList} isRed>
          <DeleteOutline /> Delete
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteListModal;
