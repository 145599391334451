import { useState, useEffect } from "react";
import { getUserBoard } from "../../services/user.service";
import UnsubscribeForm from "../FunctionalComponents/Unsubscribe";
import { useNavigate } from "react-router-dom";

const UnsubscribeContactOld = () => {
  const navigate = useNavigate();
  const [content, setContent] = useState<string>("");

  useEffect(() => {
    getUserBoard().then(
      (response: any) => {
        setContent(response.data.username);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        if (_content === "Unauthorized") {
          localStorage.removeItem("user");
          navigate("/login");
          window.location.reload();
        }

        setContent(_content);
      }
    );
  }, []);

  return (
    <div className="for-height">
      <div className="col-md-12 col-12-new">
        <div className="card card-container2 new-card">
          <h3 className="login-header">Unsubscribe/Contact Center</h3>
          <div>
            In this function you can unsubscribe users individually or in bulk
            via upload or multiple form, blacklist. Per API these users are
            blacklisted or unsubscribed in the mailingtools.
          </div>

          <div className="App">
            <UnsubscribeForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnsubscribeContactOld;
