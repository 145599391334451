import { useState, useEffect } from "react";
import { getUserBoard } from "../../services/user.service";
import { useNavigate } from "react-router-dom";

const SelectionExport = () => {
    const navigate = useNavigate();
    const [content, setContent] = useState<string>("");
    
    useEffect(() => {
        getUserBoard().then(
            (response: any) => {
                setContent(response.data.username);
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                if (_content === "Unauthorized") {
                    localStorage.removeItem("user");
                    navigate("/login");
                    window.location.reload();
                }

                setContent(_content);
            }
        );
    }, []);

    return (
        <div className="for-height">
            <div className="col-md-12 col-12-new">
                <div className="card card-container2 new-card">
                    <h3 className="login-header">Selection and export Center/Manager</h3>
                    <div>
                        In this point you should be able to select by individual lists or in total over all lists, by gender, age, street, zip code, city, purchasing power person, purchasing power household, household size, etc.
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SelectionExport;