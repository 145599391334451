import { useState, useEffect, ChangeEvent } from "react";
import { Formik, Field, Form, ErrorMessage, FormikHelpers } from "formik";
import * as Yup from "yup";

import { getUserBoard } from "../../services/user.service";
import SideBar from "../FunctionalComponents/Sidebar";
import { parseFile } from "../../utils/processFileData";
import { columns } from "../../utils/columns";
import { useNavigate } from "react-router-dom";

const ImportData = () => {
    const navigate = useNavigate();
    const [content, setContent] = useState<string>("");
    const [successful, setSuccessful] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [fieldValue, setFieldValue] = useState<any>();
    const [useColumns, setUseColumns] = useState<any>([]);
    const [useImport, setUseImport] = useState<boolean>(true);
    
    useEffect(() => {
        getUserBoard().then(
            (response: any) => {
                setContent(response.data.username);
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                if (_content === "Unauthorized") {
                    localStorage.removeItem("user");
                    navigate("/login");
                    window.location.reload();
                }

                setContent(_content);
            }
        );
    }, []);

    const initialValues: {
        file: any
    } = {
        file: []
    };;

    const validationSchema = Yup.object().shape({});

    const onChangeExportData = async (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            parseFile(event.target.files[0], setFieldValue, columns, setUseColumns);
            setUseImport(false);
        }
    }

    const handleExport = (formValue: {}, { resetForm }: FormikHelpers<any>) => {
        setMessage('message');
        setSuccessful(true);
        setUseColumns(columns);
        resetForm();
    };


    return (
        <div className="for-height">
            <SideBar />
            <div className="col-md-12">
                <div className="card card-container2" style={{ marginTop: 0, marginLeft: 250 }}>
                    <h3 className="login-header">Import data</h3>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleExport}
                    >
                        <Form>
                            <div className="form-group">
                                <label htmlFor="savedata"> Select file </label>
                                <Field
                                    name="file"
                                    type="file"
                                    className="form-control"
                                    onInput={onChangeExportData}
                                />
                                <ErrorMessage
                                    name="file"
                                    component="div"
                                    className="alert alert-danger"
                                />
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn btn-submit btn-block">Submit</button>
                            </div>
                        </Form>
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default ImportData;