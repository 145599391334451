import { IconButton } from "@mui/material";
import styles from "../../styles.module.scss";
import { DeleteOutline } from "@mui/icons-material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { IListRow } from "../../../../../types/UploadCenter";

interface IProps {
  row: IListRow;
  mapObject: { [key: string]: string };
  editFunction: (id: number) => void;
  deleteFunction: (id: number) => void;
  isPreview?: boolean;
}

const UploadedRowDisplay = ({
  row,
  mapObject,
  editFunction,
  deleteFunction,
  isPreview = false,
}: IProps) => {
  return (
    <tr className={styles.tableWrapper}>
      {Object.keys(isPreview ? row : mapObject).map((column) => (
        <td key={`row-${row.id}-${column}`}>{row[column] || "[none]"}</td>
      ))}

      {!isPreview && <td>
        <IconButton onClick={() => editFunction(row.id)} aria-label="edit">
          <EditOutlinedIcon />
        </IconButton>
        <IconButton onClick={() => deleteFunction(row.id)} aria-label="delete">
          <DeleteOutline />
        </IconButton>
      </td>}
    </tr>
  );
};

export default UploadedRowDisplay;
