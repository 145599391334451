import React from 'react';

type ImportDataContentProps = {
    onChangeImportModalData: () => void;
};

const ImportModalDataContent: React.FC<ImportDataContentProps> = ({ onChangeImportModalData }) => (
    <div>
        <p>Modal content will be placed here.</p>
        <button
            onClick={onChangeImportModalData}
            className="btn btn-submit btn-block"
        >
            Import data from DB
        </button>
    </div>
);

export default ImportModalDataContent;
