import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
interface MailingNameResponse {
    name: string;
}


export const getMailingName = async (idNumber: number | null): Promise<string> => {
    try {
        const { data } = await axios.get<MailingNameResponse>(`${API_URL}mailings/${idNumber}/name`);
        const { name } = data;
        return name;
    } catch (error) {
        console.error('Error fetching mailing name:', error);
        throw error;
    }
};
