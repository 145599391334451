import React, { useState, useEffect, ChangeEvent } from "react";
import { Formik, Field, Form, ErrorMessage, FormikHelpers } from "formik";
import * as Yup from "yup";

import { getUserBoard } from "../../services/user.service";
import { saveData } from "../../services/users-email.service";
import { insertData } from "../../services/users-data.service";
import SideBar from "../FunctionalComponents/Sidebar";
import { parseFile } from "../../utils/processFileData";
import { columns } from "../../utils/columns";
import { useNavigate } from "react-router-dom";

interface EmailSave {
    email: string;
}

const SaveData = () => {
    const navigate = useNavigate();
    const [content, setContent] = useState<string>("");
    const [successful, setSuccessful] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [fieldValue, setFieldValue] = useState<any>();
    const [newmessage, setNewmessage] = useState<string>("");
    const [useColumns, setUseColumns] = useState<any>([]);
    const [useImport, setUseImport] = useState<boolean>(true);
    
    useEffect(() => {
        getUserBoard().then(
            (response: any) => {
                setContent(response.data.username);
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                if (_content === "Unauthorized") {
                    localStorage.removeItem("user");
                    navigate("/login");
                    window.location.reload();
                }

                setContent(_content);
            }
        );
    }, []);

    const initialValues: {
        email: string;
    } = {
        email: ""
    };

    const initialFiles: {
        file: any;
    } = {
        file: []
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email("This is not a valid email.")
            .required("This field is required!")
    });

    const onChangeSaveData = async (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            parseFile(event.target.files[0], setFieldValue, columns, setUseColumns);
            setUseImport(false);
        }
    }

    const handleSaveData = async (formValue: { email: string; }, { resetForm }: FormikHelpers<any>) => {
        let { email } = formValue;

        setMessage("");
        saveData(email).then(
            (response: any) => {
                console.log('Success saved>>>');
                setUseColumns(columns);
                setSuccessful(true);
            },
            (error) => {
                const errorMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                setMessage(errorMessage);
                setSuccessful(false);
            }
        );
        setNewmessage('');
        resetForm();
    };

    const saveInsertData = (formValue: { file: any }, { resetForm }: FormikHelpers<any>) => {
        let { file } = formValue;
        if (fieldValue.length > 0) {
            file = fieldValue
            insertData(file).then(
                (response: any) => {
                    console.log('Success saved>>>');
                    setSuccessful(true);
                },
                (error) => {
                    const errorMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setMessage(errorMessage);
                    setSuccessful(false);
                }
            );
        }
        resetForm();
    };

    return (
        <div className="for-height">
            <SideBar />
            <div className="col-md-12">
                <div className="card card-container2" style={{ marginTop: 0, marginLeft: 250 }}>
                    <h3 className="login-header">Save data</h3>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSaveData}
                    >
                        <Form>
                            <div className="form-group">
                                <label htmlFor="savedata"> Single data </label>
                                <Field name="email" type="email" className="form-control" />
                                <ErrorMessage
                                    name="email"
                                    component="div"
                                    className="alert alert-danger"
                                />
                            </div>


                            <div className="form-group">
                                <button type="submit" className="btn btn-submit btn-block">Save single data</button>
                            </div>
                            {message && (
                                <div className="form-group">
                                    <div className="alert alert-danger" role="alert">
                                        {message}
                                    </div>
                                </div>
                            )}
                        </Form>
                    </Formik>
                    <Formik
                        initialValues={initialFiles}
                        onSubmit={saveInsertData}
                    >
                        <Form>
                            <div className="form-group">
                                <label htmlFor="savedata"> Select file </label>
                                <Field
                                    name="file"
                                    type="file"
                                    className="form-control"
                                    onInput={onChangeSaveData}
                                />
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn btn-submit btn-block">Save multiple data</button>
                            </div>
                            {message && (
                                <div className="form-group">
                                    <div className="alert alert-danger" role="alert">
                                        {message}
                                    </div>
                                </div>
                            )}
                        </Form>
                    </Formik>

                </div>
            </div>
        </div>
    );
};

export default SaveData;